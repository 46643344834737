import { createAction } from '@reduxjs/toolkit';
import { OverlayType } from '../../interfaces';
import { OverlayActionTypes } from '../actionTypes';

export const setOverlay = createAction(
  OverlayActionTypes.SET_OVERLAY,
  (overlayType: OverlayType, data?: any) => ({
    type: OverlayActionTypes.SET_OVERLAY,
    payload: { overlayType, data },
  }),
);

export const setNoneOverlay = () => setOverlay(OverlayType.None);
export const setPinMessageOverlay = () => setOverlay(OverlayType.PinMessage);
export const setBanControlOverlay = () => setOverlay(OverlayType.BanControl);
export const setReactionDetailsOverlay = (messageId: string) => setOverlay(
  OverlayType.ReactionDetails,
  messageId,
);
