import { ChannelResponse, ReactionResponse, UserResponse } from 'stream-chat';
import { DefaultStreamChatGenerics } from 'stream-chat-react';

export interface AlternateNames {
  [key: string]: string;
}

export interface CageInfo {
  cageCode: string
  name: string,
  shortName: string
}

export type RsiChannel = ChannelResponse & CageInfo;

export enum StreamRoles {
  admin = 'admin',
  moderator = 'moderator',
  user = 'user',
}

export interface FeedEvent {
  cagePlayerId: number,
  betWinMultiplier: number,
  consecutiveWinCount: number,
  cageCode: number,
  gameCode: string,
  nickname: string | null,
  eventType: string,
  message?: string,
  prizeTranslationKey?: string,
  winners?: WinningParticipant[],
  randomWinners?: WinningParticipant[],
}

export enum MessageStatus {
  received = 'received',
  sending = 'sending',
  failed = 'failed',
}

// We're forced to have this as we're using stream-chat-react lib
// They impose some dynamic types and we need to overwrite this
export type RsiChatGenerics = DefaultStreamChatGenerics & {
  reactionType: {
    user: UserResponse<RsiChatGenerics>,
  };
  attachmentType: {};
  channelType: {
    cageCode: number;
    cageGroup: string;
    cageName: string;
  };
  commandType: string;
  eventType: any;
  messageType: {
    feedEvent?: FeedEvent,
    latest_reactions?: ReactionResponse<RsiChatGenerics>[],
    own_reactions?: ReactionResponse<RsiChatGenerics>[],
    status: string,
    modNickname?: string,
  };
  userType: {
    image: string;
  };
};

export interface WinningParticipant {
  nickname: string,
  position: number,
}
