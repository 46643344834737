import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ChannelFilters, MessageFilters, SearchAPIResponse } from 'stream-chat';
import { useChatContext } from 'stream-chat-react';
import { DefaultStreamChatGenerics } from 'stream-chat-react/dist/types/types';
import { logger } from '../../../services';
import { searchFiltersSelector } from '../../../store/selectors/search';
import { CHANNEL_TYPE, MAX_RESULTS } from '../../../utils/common';

function getDtFilter(dtRange: Date[] | undefined) {
  if (dtRange?.length === 2) {
    const [startDate, endDate] = dtRange;
    return {
      $and: [
        { created_at: { $gte: startDate.toISOString() } },
        { created_at: { $lte: endDate.toISOString() } },
      ],
    };
  }
  return {};
}

function useMessageSearch() {
  const [searchResponse, setSearchResponse] = useState<SearchAPIResponse>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [error, setError] = useState<String | undefined>();
  const { client } = useChatContext();
  const filters = useSelector(searchFiltersSelector);

  useEffect(() => {
    if (filters) {
      setHasError(false);
      setError(undefined);
      setSearchResponse(undefined);
      setIsLoading(true);
      const {
        channels, keyword, playerName, dtRange,
      } = filters;

      const getUserFilters = async () => {
        if (!playerName) return {};

        const { users } = await client.queryUsers(
          {
            name: { $eq: playerName },
          },
        );

        const userIds = users.map((user) => user.id);
        return { 'user.id': { $in: userIds } };
      };

      const fetchData = async () => {
        const channelFilters: ChannelFilters = {
          type: CHANNEL_TYPE,
          ...(channels?.length ? {
            cid: {
              $in: channels.map((channel) => channel.channel.cid),
            },
          } : {}),
        };

        const userFilters = await getUserFilters();

        if (!playerName || (userFilters['user.id'] && userFilters['user.id'].$in.length > 0)) {
          const textFilter = keyword ? { text: { $q: keyword } } : {};

          const messageFilters = {
            ...textFilter,
            ...userFilters,
            ...getDtFilter(dtRange),
          } as MessageFilters<DefaultStreamChatGenerics>; // stream type is broken on $and object
          logger.info('Searching messages with:', channelFilters, messageFilters);

          const response = await client.search(
            channelFilters,
            messageFilters,
            {
              sort: [{ updated_at: -1 }],
              limit: MAX_RESULTS,
            },
          );
          if (response.results_warning) {
            logger.warn('Got results warning when searching messages', response.results_warning);
          }
          setIsLoading(false);
          setSearchResponse(response);
        } else {
          setHasError(true);
          setError(`No user found with name ${playerName}`);
          setIsLoading(false);
        }
      };

      fetchData()
        .catch((e) => {
          logger.error('Error searching messages', e);
          setHasError(true);
          setIsLoading(false);
        });
    }
  }, [filters, client]);

  return {
    searchResponse, isLoading, hasError, filters, maxResults: MAX_RESULTS, error,
  };
}

export default useMessageSearch;
