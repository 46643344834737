import React, { useEffect } from 'react';

import {
  MessageList,
  MessageInput,
  useChannelActionContext,
  Window,
  MessageToSend,
  useChatContext,
  useChannelStateContext,
  StreamMessage,
} from 'stream-chat-react';

import { Message } from 'stream-chat';
import { DefaultStreamChatGenerics } from 'stream-chat-react/dist/types/types';
import PinnedMessageList from '../PinnedMessageList/PinnedMessageList';
import ChannelHeader from '../ChannelHeader/ChannelHeader';
import { useCustomChatContext } from '../../contexts/CustomChatContext';
import { AlternateNames, RsiChatGenerics } from '../../interfaces';
import logger from '../../services/logger';
import { JUMP_TO_MESSAGE_EVENT, TRIVIA } from '../../utils/common';

// Some BOT messages have custom payloads that are used only on the player side
const messageFilterPredicate = (message: StreamMessage<RsiChatGenerics>) => (!!message.text?.length || message.feedEvent?.gameCode === 'BINGO' || message.feedEvent?.gameCode === TRIVIA);

function ChannelInner() {
  const { client } = useChatContext();
  const { isPinsOpen } = useCustomChatContext();
  const { sendMessage, jumpToMessage, updateMessage } = useChannelActionContext();
  const { messages, channel } = useChannelStateContext<RsiChatGenerics>();
  const filteredMessages = messages?.filter(messageFilterPredicate);

  useEffect(() => {
    const onJumpToMessage = (event: CustomEvent<string>) => {
      jumpToMessage(event.detail);
    };
    window.addEventListener(JUMP_TO_MESSAGE_EVENT, onJumpToMessage as EventListener);

    return () => {
      window.removeEventListener(JUMP_TO_MESSAGE_EVENT, onJumpToMessage as EventListener);
    };
  }, [jumpToMessage]);

  const getModNickname = () => {
    const typedAlternateNames = client.user?.alternateNames as AlternateNames;
    return typedAlternateNames?.[channel.cid];
  };

  const customSubmitHandler = (message: MessageToSend) => {
    const { id, text } = message;
    const messageToSend = { text };
    const customMessageData = {
      modNickname: getModNickname(),
    } as Partial<Message<DefaultStreamChatGenerics>>;

    // The id property should not be sent unless it's a retry, in a new message id will be undefined
    // in the payload. There is a bug in stream lib that if you send the property
    // it will break new messages
    if (id) {
      customMessageData.id = id;
    }

    logger.info(`${client.user?.id} sent a message in channel ${channel.cid}`, { messageToSend, customMessageData });
    sendMessage(messageToSend, customMessageData)
      .catch((err) => {
        logger.error('Error sending message', err);
      });
  };

  const customRetrySendMessage = async (message: StreamMessage) => {
    logger.info('Retrying message ', message);
    updateMessage({
      ...message,
      errorStatusCode: undefined,
      status: 'sending',
    });
    customSubmitHandler(message);
  };

  return (
    <div style={{ display: 'flex', width: '100%' }}>
      <Window>
        <ChannelHeader />
        <MessageList
          disableQuotedMessages
          messages={filteredMessages}
          retrySendMessage={customRetrySendMessage}
          noGroupByUser
        />
        <MessageInput grow overrideSubmitHandler={customSubmitHandler} />
      </Window>
      {isPinsOpen && <PinnedMessageList />}
    </div>
  );
}

export default ChannelInner;
