import { UserResponse } from 'stream-chat';
import { useChannelStateContext } from 'stream-chat-react';
import { AlternateNames } from '../interfaces';

function useAlternateName(user: UserResponse | null | undefined) {
  const { channel } = useChannelStateContext();

  if (!user) return null;

  let alternateName = user.name;

  if (user && user.alternateNames && channel) {
    const typedAlternateNames = user.alternateNames as AlternateNames;
    if (typedAlternateNames[channel.cid]) {
      alternateName = typedAlternateNames[channel.cid];
    }
  }

  return alternateName;
}

export default useAlternateName;
