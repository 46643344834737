import React, { MouseEvent, useState } from 'react';
import { useChatContext } from 'stream-chat-react';
import { logger } from '../../services';

enum ButtonStatus {
  UNBAN = 'Unban',
  REQUESTING = 'Requesting',
  UNBANNED = 'Unbanned',
  FAILED = 'Unban Failed, try again',
  BAD_DATA = 'Bad Data, try refresh'
}

function UnbanButton({ channelId, userId }: { channelId: string, userId: string }) {
  const [buttonStatus, setButtonStatus] = useState<ButtonStatus>(ButtonStatus.UNBAN);
  const { client } = useChatContext();

  const handleClick = async (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (client && channelId && userId) {
      setButtonStatus(ButtonStatus.REQUESTING);
      try {
        const channels = await client.queryChannels({ cid: { $in: [channelId] } });
        await channels[0].unbanUser(userId);
        setButtonStatus(ButtonStatus.UNBANNED);
      } catch (err) {
        logger.error('Unban user error: ', err);
        setButtonStatus(ButtonStatus.FAILED);
      }
    } else {
      setButtonStatus(ButtonStatus.BAD_DATA);
    }
  };

  return (
    <button
      type="button"
      onClick={handleClick}
      disabled={[ButtonStatus.REQUESTING, ButtonStatus.UNBANNED].includes(buttonStatus)}
    >
      {buttonStatus}
    </button>
  );
}

export default UnbanButton;
