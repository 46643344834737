import { useOktaAuth } from '@okta/okta-react';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import ChatContainer from './components/ChatContainer/ChatContainer';
import logger from './services/logger';
import { streamActions } from './store/actions';
import { streamTokenSelector } from './store/selectors/stream';
import defaultTheme from './themes/default';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
  }
`;

const urlParams = new URLSearchParams(window.location.search);

function App() {
  const dispatch = useDispatch();
  const streamToken = useSelector(streamTokenSelector);
  const { authState } = useOktaAuth();
  const { pathname } = useLocation();

  logger.info('OKTA is authenticated?', authState?.isAuthenticated);
  if (authState?.error) {
    logger.error('OKTA error?', authState.error);
  }

  useEffect(() => {
    if (!streamToken && authState?.isAuthenticated) {
      dispatch(streamActions.getStreamAuthInfo(`Bearer ${authState?.accessToken?.accessToken!}`));
    } else if (pathname === '/skipAuth') {
      const user = urlParams.get('userId') || 'localmodtest4';
      const streamTokenParam = urlParams.get('user_token') || 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoibG9jYWxtb2R0ZXN0NCJ9.pbES3UuGrs415jwGgxCIjh8CSHznJrSF4ftmtxVwlgM';
      dispatch(streamActions.setStreamAuthInfo(streamTokenParam, user));
    }
  }, [authState, streamToken, pathname, dispatch]);

  if (!streamToken) {
    logger.info('No stream token');
    return <div>Authenticating...</div>;
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <ChatContainer />
      <GlobalStyle />
    </ThemeProvider>
  );
}

export default App;
