import React from 'react';
import { FaTimesCircle } from 'react-icons/fa';
import { useCustomChatContext } from '../contexts/CustomChatContext';

function CloseDeleteChannel() {
  const { setDeletingChannel, setIsEditingChannel } = useCustomChatContext();

  return (
    <FaTimesCircle
      fill="var(--primary-color)"
      size={32}
      onClick={() => {
        if (setDeletingChannel) setDeletingChannel(false);
        if (setIsEditingChannel) setIsEditingChannel(false);
      }}
    />
  );
}

export default CloseDeleteChannel;
