export enum OverlayType {
  PinMessage = 'PinMessage',
  MessageSearchFilter = 'MessageSearchFilter',
  BanControl = 'BanControl',
  ReactionDetails = 'ReactionDetails',
  None = 'None'
}

export type OverlayState = {
  type: OverlayType;
  data?: any;
};
