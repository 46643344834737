import React from 'react';
import { MessageResponse, SearchAPIResponse } from 'stream-chat';
import styled from 'styled-components';
import ChannelGroupMessages from './ChannelGroupMessages';

const GroupsWrapper = styled.div`
  width: 100%;
`;

interface MessageListProps {
  searchResponse: SearchAPIResponse;
  maxResults: number;
}

function renderGroupedResponse(groupedResponse: { [key: string]: MessageResponse[] }) {
  return Object.entries(groupedResponse)
    .map(([key, messages]) => <ChannelGroupMessages key={key} messages={messages} />);
}

function MessageSearchResponseList(props: MessageListProps) {
  const { searchResponse, maxResults } = props;

  const groupedResponse = searchResponse
    .results
    .reduce((groups: { [key: string]: MessageResponse[] }, item) => {
      const { message } = item;
      const { cid } = message;

      if (!cid) return groups;

      const group = (groups[cid] || []);
      group.push(message);
      // eslint-disable-next-line no-param-reassign
      groups[cid] = group;
      return groups;
    }, {});

  return (
    <GroupsWrapper>
      {`${searchResponse.results.length} result(s) found. Response is limited to ${maxResults} records.`}
      {renderGroupedResponse(groupedResponse)}
    </GroupsWrapper>
  );
}

export default MessageSearchResponseList;
