import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import {
  ChatAutoComplete,
  useChannelStateContext,
  useMessageInputContext,
} from 'stream-chat-react';

import styled from 'styled-components';

import {
  SendButton,
  SmileyFace,
} from '../../assets';
import { useCustomChatContext } from '../../contexts/CustomChatContext';
import EventEmitter from '../../utils/eventEmitter';
import { CopyNicknameEvent } from '../../interfaces/Events';
import { Emoji } from '../../interfaces/Emoji';
import EmojiPicker from '../EmojiPicker/EmojiPicker';

const CustomMessageInputWrapper = styled.div`
  position: relative;
  background: #f7f6f8;
  padding: 12px;
  width: 100%;

  &.thread-open {
    border-bottom-right-radius: 0px;
  }

  .message-input__input {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 8px;
  }

  .message-input__input:focus-within {
    border-color: var(--primary-color);
  }

  .message-input__top {
    min-height: 43px;
    display: flex;
    align-items: center;
    width: 100%;
    background: #fff;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  & .giphy-icon__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    height: 24px;
    width: 63px;
    background: var(--primary-color);
    border-radius: 12px;
    margin-left: 8px;
  }

  & .giphy-icon__text {
    font-family: Helvetica Neue, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 8px;
    color: #ffffff;
  }

  .message-input__bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 40px;
    background: rgba(0, 0, 0, 0.05);

    .char-count {
      font-size: 14px;
      padding-right: 10px;
    }
  }

  .message-input__button {
    position: relative;
    width: 24px;
    padding-left: 4px;
    cursor: pointer;
  }

  .message-input__icons svg:hover path {
    fill: var(--primary-color);
    fill-opacity: 1;
  }

  .message-input__input:focus-within .message-input__button path {
    fill: var(--primary-color);
    fill-opacity: 1;
  }

  & .str-chat__textarea textarea {
    display: flex;
    background: #fff;
    border: none;
    outline: none;
    font-family: Helvetica Neue, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  & .str-chat__textarea textarea:focus {
    border: none;
    outline: none;
    box-shadow: none;
    background: #fff;
  }

  & .str-chat__textarea textarea::placeholder {
    color: rgba(0, 0, 0, 0.2);
  }

  .message-input__icons {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .message-input__icons div:first-child {
    margin: 0 5px;
  }

  .message-input__icons div:nth-child(3) {
    margin-left: 5px;
  }

  .message-input__icons svg {
    width: 36px;
  }

  .message-input__icons svg:hover {
    cursor: pointer;
  }

  .icon-divider {
    width: 1px;
    height: 40px;
    background: rgba(204, 204, 204, 0.5);
  }

  & .str-chat__input--emojipicker {
    z-index: 3;
    position: absolute;
    bottom: 112px;
    left: 20px;
    width: 338px;
  }

  .emoji-mart .emoji-mart-emoji:focus {
    outline: none;
  }

  div.rfu-dropzone:focus {
    outline: none;
  }

  .rfu-image-previewer {
    flex: none;
    margin-left: 12px;
  }

  .rfu-image-previewer__image {
    margin-bottom: 0;
  }

  div.rta__autocomplete.str-chat__emojisearch {
    z-index: 10;
    position: relative;
    width: 100%;
    background: #fff;
    margin: 0;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .rta__entity--selected {
    background: var(--primary-color);
  }

  .str-chat__slash-command:hover {
    background: var(--primary-color);
    cursor: pointer;
  }

  .rta__list-header {
    font-family: Helvetica Neue, sans-serif;
    font-size: 14px;
    line-height: 16px;
    color: #2c2c30;
    mix-blend-mode: normal;
    opacity: 0.9;
  }
`;

const DEFAULT_MAX_MESSAGE_LENGTH = 280;

function CustomMessageInput() {
  const { isReadOnly } = useCustomChatContext();
  const {
    textareaRef, insertText, handleSubmit, text,
  } = useMessageInputContext();
  const { channel, channelConfig } = useChannelStateContext();
  const maxMessageLength = channelConfig?.max_message_length || DEFAULT_MAX_MESSAGE_LENGTH;
  const emojiBtnElement = useRef<HTMLDivElement>(null);
  const [pickerIsOpen, setPickerIsOpen] = useState(false);

  const onEmojiSelect = ({ native }: Emoji) => {
    insertText(native);
    textareaRef.current?.focus();
  };

  const onNicknameClickedHandler = useCallback(({ channelId, content }: CopyNicknameEvent) => {
    if (channel.id === channelId) {
      insertText(content.concat(' '));
    }
  }, [channel.id, insertText]);

  useEffect(() => {
    const listener = EventEmitter.addListener('nicknameClicked', onNicknameClickedHandler);
    return () => {
      listener.remove();
    };
  }, [onNicknameClickedHandler]);

  if (isReadOnly) {
    return null;
  }

  return (
    <CustomMessageInputWrapper>
      <div className="message-input__input">
        <div className="message-input__top">
          <ChatAutoComplete placeholder="Type message" />
          <div
            onKeyDown={() => null}
            role="button"
            tabIndex={0}
            className="message-input__button"
            aria-roledescription="button"
            onClick={handleSubmit}
          >
            <SendButton />
          </div>
        </div>
        <div className="message-input__bottom">
          <div className="message-input__icons">
            <SmileyFace openEmojiPicker={() => setPickerIsOpen((state) => !state)} />
            {pickerIsOpen && (
              <EmojiPicker
                onEmojiSelect={onEmojiSelect}
                closePicker={() => setPickerIsOpen(false)}
                triggerElement={emojiBtnElement}
              />
            )}
            <div className="icon-divider" />
          </div>
          <div className="char-count">
            {`${text.length} / ${maxMessageLength}`}
          </div>
        </div>
      </div>
    </CustomMessageInputWrapper>
  );
}

export default CustomMessageInput;
