/* eslint-disable no-alert */
import React, { useRef, useState } from 'react';
import { useChatContext } from 'stream-chat-react';
import styled from 'styled-components';
import { logger } from '../../services';
import { MAX_NICKNAME_LENGTH } from '../../utils/common';

const UsernameSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  .name {
    font-weight: bold;
    font-size: 18px;
  }

  .warning {
    color: red;
    font-size: 16px;
    font-weight: bold;
    background-color: white;
  }
`;

function UsernameSection() {
  const { client } = useChatContext();
  const usernameRef = useRef<HTMLInputElement>(null);
  const [name, setName] = useState<string | undefined>(client.user?.name);
  const [isEditing, setIsEditing] = useState(false);

  if (name && !isEditing) {
    return (
      <UsernameSectionWrapper>
        <span className="name">
          {name}
        </span>
        <button type="button" onClick={() => setIsEditing(true)}>Change name</button>
      </UsernameSectionWrapper>
    );
  }

  const saveName = async () => {
    if (usernameRef.current?.value && client.user) {
      const newName = usernameRef.current.value;
      const user = {
        id: client.user.id,
        set: {
          name: newName,
        },
      };
      try {
        await client.partialUpdateUser(user);
        alert('Name updated successfully');
        setName(newName);
        setIsEditing(false);
      } catch (e: any) {
        logger.error('Error updating name', e);
        alert(`Server error updating your name. ${e.message}`);
      }
    } else {
      alert('Please provide a name');
    }
  };

  return (
    <UsernameSectionWrapper>
      <input ref={usernameRef} placeholder="Set your name" maxLength={MAX_NICKNAME_LENGTH} />
      {!isEditing && <span className="warning">Please set your name</span>}
      <button onClick={saveName} type="button">Save</button>
    </UsernameSectionWrapper>
  );
}

export default UsernameSection;
