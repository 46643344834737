import React from 'react';

function SendButton() {
  return (
    <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.5 12L13.5 6L0.5 0V4.66666L9.8125 6L0.5 7.33334V12Z"
        fill="black"
        fillOpacity="0.33"
      />
    </svg>
  );
}

export default SendButton;
