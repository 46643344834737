import styled from 'styled-components';

const Input = styled.input`
  border-color: lightgrey;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  height: 34px;
  width: 100%;
`;

export default Input;
