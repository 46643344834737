import React, { Suspense } from 'react';
import { CustomEmojiProps } from './CustomEmoji';

const CustomEmoji = React.lazy(
  () => import('./CustomEmoji'),
);

function LazyCustomEmoji(props: Readonly<CustomEmojiProps>) {
  const { size, type } = props;
  return (
    <Suspense>
      <CustomEmoji aria-roledescription="figure" size={size} type={type} />
    </Suspense>
  );
}

export default LazyCustomEmoji;
