import { createReducer } from '@reduxjs/toolkit';
import { SearchState } from '../../interfaces';
import { setSearchFilters } from '../actions/search';

const initialState: SearchState = { filters: undefined };

export default createReducer(initialState, (builder) => {
  builder
    .addCase(setSearchFilters, (state, { payload }) => {
      state.filters = payload;
    });
});
