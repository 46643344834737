import { combineReducers } from 'redux';
import examples from './examples';
import configs from './configs';
import channelTabs from './channelTabs';
import stream from './stream';
import overlay from './overlay';
import channelOperations from './channelOperations';
import search from './search';
import pinnedMessage from './pinnedMessage';

export default combineReducers({
  examples,
  /* TODO: SPORT-2926 Deprecate store configs */
  configs,
  channelTabs,
  channelOperations,
  stream,
  overlay,
  search,
  pinnedMessage,
});
