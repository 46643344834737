import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../interfaces';

const selectSelf = (state: RootState) => state.channelTabs;

// eslint-disable-next-line import/prefer-default-export
export const channelTabsSelector = createSelector(
  selectSelf,
  (channelTabs) => channelTabs.channels,
);

export const activeChannelIds = createSelector(
  selectSelf,
  (channelTabs) => channelTabs.channels.map((channel) => channel.id),
);
