/* eslint-disable import/prefer-default-export */

/**
 * This method will take a regular message, check if it has a URLs
 * and return a message with anchor tags instead of URLs.
 * NOTE: When internal link is generated, there is a trailing space after href
 * @param message moderator/player message
 * @returns string with anchor tags instead of URLs
 */
export const linkifyMessage = (message?: string) => {
  if (!message) return '';
  const urlRegex = /(https:\/\/)(www\.)?[^\s]+\.[^\s]+/g;
  return message.replace(urlRegex, (url) => {
    const isInternal = url.startsWith(window.location.origin);
    return `<a href="${url}" ${isInternal ? '' : 'target="_blank"'}>${url}</a>`;
  });
};

/**
 *
 * Given a string parameter it will generate a colour
 */
/* eslint-disable no-bitwise */
export const stringToColour = (str: string) => {
  let hash = 0;
  str.split('').forEach((char) => {
    hash = char.charCodeAt(0) + ((hash << 5) - hash);
  });
  let colour = '#';
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    colour += value.toString(16).padStart(2, '0');
  }
  return colour;
};
