import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../interfaces';

const selectSelf = (state: RootState) => state.overlay;

export const overlaySelector = createSelector(
  selectSelf,
  (overlay) => overlay.type,
);

export const overlayDataSelector = createSelector(
  selectSelf,
  (overlay) => overlay.data as string,
);
