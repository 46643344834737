import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../interfaces';

const selectSelf = (state: RootState) => state.stream;

// eslint-disable-next-line import/prefer-default-export
export const streamTokenSelector = createSelector(
  selectSelf,
  (stream) => stream.token,
);

export const streamIdSelector = createSelector(
  selectSelf,
  (stream) => stream.id,
);
