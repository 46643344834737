import env from '../utils/env';

if (RSIStandaloneMfeUtils && env.REACT_APP_DEBUG_LOG_URL) {
  window.args = { ...window.args, debugLog: env.REACT_APP_DEBUG_LOG_URL };
  RSIStandaloneMfeUtils.setupRsiApi(); // required for rsi-logger
}

const logger = new RSILogger([env.REACT_APP_TEAM, env.REACT_APP_TAG]);

window.onerror = (message, file, line, column, errorObject) => {
  logger.error('Uncaugth error', {
    message, file, line, column, errorObject,
  });
};

export default logger;
