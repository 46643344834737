import { UserResponse } from 'stream-chat';
import { StreamRoles } from '../interfaces';

// eslint-disable-next-line import/prefer-default-export
export const isUserAdminOrModerator = (user: UserResponse | null | undefined) => {
  if (user) {
    return user.role === StreamRoles.admin || user.role === StreamRoles.moderator;
  }

  return false;
};
