import React, {
  PropsWithChildren, useContext, useMemo, useState,
} from 'react';
import { Channel, StreamChat } from 'stream-chat';
import { RsiChatGenerics } from '../interfaces';

export type CustomChatContextValue = {
  isCreatingChannel: boolean;
  setIsCreatingChannel: React.Dispatch<React.SetStateAction<boolean>>;
  isEditingChannel: boolean;
  setIsEditingChannel: React.Dispatch<React.SetStateAction<boolean>>;
  isPinsOpen: boolean;
  setIsPinsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isDeletingChannel: boolean;
  setDeletingChannel: React.Dispatch<React.SetStateAction<boolean>>;
  isReadOnly: boolean;
  focusedChannel: Channel<RsiChatGenerics> | undefined;
  setFocusedChannel: React.Dispatch<React.SetStateAction<Channel<RsiChatGenerics> | undefined>>;
};

const CustomChatContext = React.createContext<CustomChatContextValue | undefined>(undefined);

interface CustomChatProviderProps {
  client: StreamChat
}

export function CustomChatProvider(props: PropsWithChildren<CustomChatProviderProps>) {
  const { children, client } = props;

  const [isCreatingChannel, setIsCreatingChannel] = useState(false);
  const [isEditingChannel, setIsEditingChannel] = useState(false);
  const [isDeletingChannel, setDeletingChannel] = useState(false);
  const [isPinsOpen, setIsPinsOpen] = useState(false);
  const isReadOnly = client.user?.role === 'readonly';
  const [focusedChannel, setFocusedChannel] = useState<Channel<RsiChatGenerics>>();

  const CustomChatContextValue: Required<CustomChatContextValue> = useMemo(() => ({
    isCreatingChannel,
    setIsCreatingChannel,
    isEditingChannel,
    setIsEditingChannel,
    isPinsOpen,
    setIsPinsOpen,
    isDeletingChannel,
    setDeletingChannel,
    isReadOnly,
    focusedChannel,
    setFocusedChannel,
  }), [isCreatingChannel,
    setIsCreatingChannel,
    isEditingChannel,
    setIsEditingChannel,
    isPinsOpen,
    setIsPinsOpen,
    isDeletingChannel,
    setDeletingChannel,
    isReadOnly,
    focusedChannel,
    setFocusedChannel,
  ]);

  return (
    <CustomChatContext.Provider value={CustomChatContextValue}>
      {children}
    </CustomChatContext.Provider>
  );
}

export const useCustomChatContext = () => {
  const contextValue = useContext(CustomChatContext);
  return contextValue as Required<CustomChatContextValue>;
};

export default CustomChatContext;
