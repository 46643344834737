import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { OverlayType } from '../../interfaces';
import { overlaySelector } from '../../store/selectors/overlay';
import PinMessageComponent from '../PinMessage/PinMessage';
import MessageSearchFilterComponent from './MessageSearchFilter/MessageSearchFilter';
import BanControlComponent from '../BanControl';
import { setNoneOverlay } from '../../store/actions/overlay';
import ReactionDetailsComponent from '../Reactions/ReactionDetails';

const {
  None, PinMessage, BanControl, MessageSearchFilter, ReactionDetails,
} = OverlayType;

const OverlayDiv = styled.div<{ type: OverlayType }>`
  display: flex;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  width: 100vw;
  height: 100vh;
  z-index: 20;
  justify-content: center;
  align-items: center;
`;

const CloseBtn = styled.button`
  background-color: transparent;
  border: none;
  position: absolute;
  top: 0;
  right: 0;
  color: #fff;
  font-size: large;
  padding: 16px;
  border-bottom-left-radius: 8px;

  &:hover {
    background-color: rgba(256, 256, 256, 0.2);
    cursor: pointer;
  }
`;

const ContentDiv = styled.div`
  max-height: 80vh;
  max-width: 80vw;
  background-color: white;
  border-radius: 30px;
  padding: 16px;
`;

function Overlay() {
  const overlayType = useSelector(overlaySelector);
  const dispatch = useDispatch();
  const hasOverlay = overlayType !== None;

  const renderOverlayContent = () => {
    switch (overlayType) {
      case PinMessage:
        return <PinMessageComponent />;
      case MessageSearchFilter:
        return <MessageSearchFilterComponent />;
      case BanControl:
        return <BanControlComponent />;
      case ReactionDetails:
        return <ReactionDetailsComponent />;
      default: return null;
    }
  };

  const onCloseClick = useCallback(() => {
    dispatch(setNoneOverlay());
  }, [dispatch]);

  useEffect(() => {
    const escFunction = (event: { key: string; }) => {
      if (event.key === 'Escape') {
        onCloseClick();
      }
    };

    if (hasOverlay) {
      document.addEventListener('keydown', escFunction, false);
    }

    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, [hasOverlay, onCloseClick]);

  return hasOverlay ? (
    <OverlayDiv onClick={onCloseClick} type={overlayType}>
      <CloseBtn onClick={onCloseClick}>X</CloseBtn>
      <ContentDiv onClick={(e) => e.stopPropagation()}>
        {renderOverlayContent()}
      </ContentDiv>
    </OverlayDiv>
  ) : null;
}

export default Overlay;
