import { Channel } from 'stream-chat';
import { createAction } from '@reduxjs/toolkit';
import ChannelOperationsActionTypes from '../actionTypes/channelOperations';
import { RsiChatGenerics } from '../../interfaces';

export const deleteChannel = createAction<Channel<RsiChatGenerics> | undefined>(
  ChannelOperationsActionTypes.DELETE_CHANNEL,
);

export default deleteChannel;
