import React from 'react';
import {
  areMessageUIPropsEqual,
  MessageContextValue,
  MessageUIComponentProps,
  useMessageContext,
  ErrorIcon,
} from 'stream-chat-react';
import styled from 'styled-components';
import { CustomMessageText } from './CustomMessageText';
import { MessageStatus, RsiChatGenerics } from '../../interfaces';
import MessageStatusIndicator from './MessageStatusIndicator';
import ReactionList from '../Reactions/ReactionList';

const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;

  &:hover {
    background: ${({ theme }) => theme.colors.neutral_100};
  }
`;

const MessageInner = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  min-height: 30px;

  &:hover .message-time{
    display: inline;
  }

  &:hover .btn-reaction{
    display: flex !important;
  }
`;

const FailedMessage = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  .text {
    margin-left: 5px;
  }
`;

function MessageSimpleWithContext(props: MessageContextValue<RsiChatGenerics>) {
  const { message, isMyMessage, handleRetry } = props;
  const hasReactions = !!message.latest_reactions?.length;
  const { status } = message;

  if (status === MessageStatus.failed) {
    return (
      <FailedMessage
        className="str-chat__message-team-failed"
        onClick={message.errorStatusCode !== 403 ? () => handleRetry(message) : undefined}
      >
        <ErrorIcon />
        {message.errorStatusCode !== 403
          ? 'Message Failed - Click to retry'
          : 'Message Failed - Unauthorized'}
        <div className="text">
          {message.text}
        </div>
      </FailedMessage>
    );
  }

  return (
    <MessageWrapper>
      <MessageInner className={message.pinned ? 'pinned-message' : 'unpinned-message'}>
        <MessageStatusIndicator />
        <CustomMessageText message={message} isMyMessage={isMyMessage()} />
      </MessageInner>
      {hasReactions && <ReactionList />}
    </MessageWrapper>

  );
}

const MemoizedMessageSimple = React.memo(
  MessageSimpleWithContext,
  areMessageUIPropsEqual,
) as typeof MessageSimpleWithContext;

export default function CustomMessage(props: MessageUIComponentProps<RsiChatGenerics>) {
  const messageContext = useMessageContext<RsiChatGenerics>('MessageSimple');

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <MemoizedMessageSimple {...messageContext} {...props} />;
}
