import React, { useState } from 'react';
import styled from 'styled-components';
import Select, { SingleValue } from 'react-select';
import { useChatContext } from 'stream-chat-react';
import ChannelActions from './ChatActions';
import Container from '../elements/Container';
import env from '../../utils/env';
import useChannelsContext from '../../contexts/ChannelsContext';
import { ChannelOptions } from '../../interfaces';
import CustomChannelList from '../CustomChannelList/CustomChannelList';

const SidebarWrapper = styled.div`
  display: flex;
  height: 100%;
  box-shadow: inset 1px 0px 0px rgba(0, 0, 0, 0.1);
  flex-direction: column;
  background: var(--primary-color);
  min-width: 250px;
  width: 250px;
`;

const ChannelListHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`;

const HeaderText = styled.p`
  font-family: Helvetica Neue, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
  color: #ffffff;
`;

type OptionType = {
  value: string;
  label: string;
};

enum CageGroup {
  Latam = 'Latam',
  NA = 'NA',
  C4F = 'C4F',
  Other = 'Other'
}

const cageGroupTitles: { [key in CageGroup]: string } = {
  [CageGroup.Latam]: 'Latin America',
  [CageGroup.NA]: 'North America',
  [CageGroup.C4F]: 'Casino for Fun',
  [CageGroup.Other]: 'Other groups',
};

function CompanyHeader() {
  const environment = env.REACT_APP_ENV;
  return (
    <ChannelListHeader>
      <HeaderText>
        RSI MOD CHAT -
        {' '}
        {environment}
      </HeaderText>
      <ChannelActions />
    </ChannelListHeader>
  );
}

function Sidebar() {
  const { channels } = useChannelsContext();
  const [selectedChannel, setSelectedChannel] = useState<OptionType>();
  const { client } = useChatContext();

  const getUniqueCageGroups = (channelOptions: ChannelOptions[]) => {
    const uniqueCageGroups = new Set<string>();
    channelOptions.forEach((channelOption:ChannelOptions) => {
      if (channelOption.channel.data?.cageGroup) {
        uniqueCageGroups.add(channelOption.channel.data.cageGroup);
      } else {
        uniqueCageGroups.add(CageGroup.Other);
      }
    });
    return Array.from(uniqueCageGroups).sort((a, b) => a.localeCompare(b));
  };

  const onChannelsChange = (option: SingleValue<OptionType>) => {
    setSelectedChannel(option as OptionType);
  };

  const getCageGroupTitle = (group: CageGroup) => cageGroupTitles[group];

  const getChannelGroupList = () => getUniqueCageGroups(channels).map((channel) => ({
    value: channel,
    label: getCageGroupTitle(channel as CageGroup),
  }));

  const filteredChannels = channels.filter(
    (channel) => channel.channel.data?.cageGroup === selectedChannel?.value,
  );

  return (
    <SidebarWrapper>
      <CompanyHeader />
      {client.user?.name && (
        <Container>
          <Select
            placeholder="Select a channel"
            onChange={onChannelsChange}
            value={selectedChannel}
            options={getChannelGroupList()}
          />
        </Container>
      )}
      {selectedChannel && (
        <CustomChannelList channels={filteredChannels} />
      )}
    </SidebarWrapper>
  );
}

export default Sidebar;
