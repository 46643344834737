/**
 * TODO: SPORT-2926 Deprecate store configs
 */
 enum ConfigsActionTypes {
  GET_API_CONFIG_REQUEST = 'configs/GET_API_CONFIG_REQUEST',
  GET_API_CONFIG_SUCCESS = 'configs/GET_API_CONFIG_SUCCESS',
  GET_API_CONFIG_FAILURE = 'configs/GET_API_CONFIG_FAILURE',

  GET_LANGUAGE_CONFIG_REQUEST = 'configs/GET_LANGUAGE_CONFIG_REQUEST',
  GET_LANGUAGE_CONFIG_SUCCESS = 'configs/GET_LANGUAGE_CONFIG_SUCCESS',
  GET_LANGUAGE_CONFIG_FAILURE = 'configs/GET_LANGUAGE_CONFIG_FAILURE',

  GET_TIME_CONFIG_REQUEST = 'configs/GET_TIME_CONFIG_REQUEST',
  GET_TIME_CONFIG_SUCCESS = 'configs/GET_TIME_CONFIG_SUCCESS',
  GET_TIME_CONFIG_FAILURE = 'configs/GET_TIME_CONFIG_FAILURE',

  DEFAULT = 'configs/DEFAULT'
}

export default ConfigsActionTypes;
