import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { ReactionResponse } from 'stream-chat';
import { overlayDataSelector } from '../../store/selectors/overlay';
import LazyCustomEmoji from './LazyCustomEmoji';
import { RsiChatGenerics } from '../../interfaces';
import { useCustomChatContext } from '../../contexts/CustomChatContext';

const ReactionDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 500px;
  padding: 16px;
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    font-size: ${theme.fontSize.text_xs};
    line-height: ${theme.lineHeight.text_xs};
    font-weight: ${theme.fontWeight.medium};
  `}
`;

const ReactionDetailsItem = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 7px 5px;

  &:nth-child(odd) {
    background-color: ${({ theme }) => theme.colors.neutral_100};
  }

  .bs-user{
    width: 90%;
    ${({ theme }) => css`
      font-size: ${theme.fontSize.text_md};
      color: ${theme.colors.neutral_400};
      font-weight: ${theme.fontWeight.regular};
    `}
  }

  .bs-emoji{
    width: 10%;
  }
`;

function ReactionDetails() {
  const messageId = useSelector(overlayDataSelector);
  const [reactions, setReactions] = useState<ReactionResponse<RsiChatGenerics>[]>([]);
  const { focusedChannel: channel } = useCustomChatContext();

  useEffect(() => {
    if (messageId) {
      channel?.getReactions(messageId, {
        limit: 999,
      })
        .then((response) => {
          setReactions(response.reactions.sort((a, b) => {
            if (a.type > b.type) {
              return 1;
            }
            if (b.type > a.type) {
              return -1;
            }
            return 0;
          }));
        });
    }
  }, [messageId, channel]);

  return (
    <ReactionDetailsWrapper>
      {reactions?.map((item) => (
        <ReactionDetailsItem key={`${item.user_id}-${item.type}`}>
          <div className="bs-user">{item.user.name}</div>
          <div className="bs-emoji">
            <LazyCustomEmoji type={item.type} size={14} />
          </div>
        </ReactionDetailsItem>
      ))}
    </ReactionDetailsWrapper>
  );
}

export default ReactionDetails;
