import React, { useEffect, useRef } from 'react';
import Picker from '@emoji-mart/react';
import styled from 'styled-components';
import { Emoji } from '../../interfaces/Emoji';

const EmojiPickerWrapper = styled.div`
  position: absolute;
  width: 70%;
  z-index: 100;
  left: 0;
  bottom: 50px;
  
  em-emoji-picker {
    width: 100% !important;
    height: 360px;
    border: 1px solid ${({ theme }) => theme.colors.black_alpha_30};
  }
`;

interface EmojiPickerProps {
  onEmojiSelect: (emoji: Emoji) => void;
  closePicker: () => void;
  /** The element provided will be ignored when evaluating the pointerDown event */
  triggerElement?: React.RefObject<HTMLElement>;
}

function EmojiPicker(props: Readonly<EmojiPickerProps>) {
  const { onEmojiSelect, closePicker, triggerElement } = props;
  const pickerElement = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handlePointerDown = (e: PointerEvent) => {
      const target = e.target as HTMLElement;

      if (pickerElement?.current?.contains(target)
        || triggerElement?.current?.contains(target)) return;

      closePicker();
    };

    window.addEventListener('pointerdown', handlePointerDown);
    return () => window.removeEventListener('pointerdown', handlePointerDown);
  }, [pickerElement, closePicker, triggerElement]);

  return (
    <EmojiPickerWrapper
      className="custom-emoji-picker"
      ref={pickerElement}
      onClick={(e: React.BaseSyntheticEvent) => { e.stopPropagation(); }}
    >
      <Picker
        data={async () => (await import('@emoji-mart/data')).default}
        skinTonePosition="search"
        maxFrequentRows={1}
        previewPosition="none"
        dynamicWidth
        theme="light"
        onEmojiSelect={(emoji: Emoji) => {
          closePicker();
          onEmojiSelect(emoji);
        }}
      />
    </EmojiPickerWrapper>
  );
}

EmojiPicker.defaultProps = {
  triggerElement: undefined,
};

export default EmojiPicker;
