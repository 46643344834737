import React, { useEffect, useRef, useState } from 'react';
import Select, { MultiValue } from 'react-select';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { ChannelOptions } from '../../../interfaces';
import MessageSearchResponseList from './MessageSearchResponseList';
import { searchActions } from '../../../store/actions';
import useMessageSearch from './useMessageSearch';
import useChannelsContext from '../../../contexts/ChannelsContext';
import SubmitBtn from '../../elements/SubmitBtn';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  align-items: center;
  width: 500px;
  font-family: ${({ theme }) => theme.fontFamily};
  background-color: white;
  padding: 16px;
  border-radius: 25px;

  .channelSelector{
    width: 100%;
  }

  .search-input, .react-daterange-picker__wrapper {
    border-color: lightgrey;
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
  }

  .input-row{
    display: flex;
    flex-direction: row;
    gap: 5px;
    width: 100%;
    height: 38px;
  }

  .input-row > input{
    flex: 1;
  }

  .error{
    color: red;
  }
`;

function MessageSearchFilter() {
  const [dtRange, setDtRange] = useState<Date[] | undefined>(undefined);
  const [selectedChannels, setSelectedChannels] = useState<ChannelOptions[]>();
  const { channels } = useChannelsContext();
  const playerNameRef = useRef<HTMLInputElement>(null);
  const keyWordRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();
  const {
    searchResponse, isLoading, hasError, filters, maxResults, error,
  } = useMessageSearch();

  const onChannelsChange = (options: MultiValue<ChannelOptions>) => {
    setSelectedChannels([...options]);
  };

  useEffect(() => {
    if (filters) {
      if (keyWordRef.current && filters.keyword) {
        keyWordRef.current.value = filters.keyword;
      }
      if (playerNameRef.current && filters.playerName) {
        playerNameRef.current.value = filters.playerName;
      }
      if (filters.channels) {
        setSelectedChannels(filters.channels);
      }
    }
    // this is supposed to run on first run only
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmitClick = () => {
    if (keyWordRef.current?.value || playerNameRef.current?.value) {
      dispatch(searchActions.setSearchFilters({
        channels: selectedChannels,
        playerName: playerNameRef.current?.value,
        keyword: keyWordRef.current?.value,
        dtRange,
      }));
    }
  };

  return (
    <Container>
      <Select
        placeholder="Select channel(s)"
        onChange={onChannelsChange}
        value={selectedChannels}
        options={channels}
        isMulti
        className="channelSelector"
      />
      <div className="input-row">
        <input
          className="search-input"
          placeholder="Player name (exact match)"
          type="text"
          ref={playerNameRef}
        />
        <input
          className="search-input"
          placeholder="Keywords"
          type="text"
          ref={keyWordRef}
        />
      </div>
      <div className="input-row">
        <DateRangePicker
          format="MM/dd/yyyy"
          maxDate={new Date()}
          onChange={(val) => setDtRange(val as any)} // type is broken on the lib
          value={dtRange || ''}
        />
      </div>
      <SubmitBtn disabled={isLoading} onClick={onSubmitClick}>Search</SubmitBtn>
      {hasError && <span className="error">{error || 'Error loading messages. Try a more granular search.'}</span>}
      {isLoading && <span>Loading...</span>}
      {searchResponse?.results.length && (
        <MessageSearchResponseList searchResponse={searchResponse} maxResults={maxResults} />
      )}
    </Container>
  );
}

export default MessageSearchFilter;
