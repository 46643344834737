/* eslint-disable import/prefer-default-export */
import { AxiosInstance } from 'axios';
import env from '../../utils/env';
import { StreamActionTypes } from '../actionTypes';

const url = env.REACT_APP_LAMBDA_URL;

export const getStreamAuthInfo = (oktaToken: string) => ({
  type: StreamActionTypes.GET_STREAM_TOKEN_REQUEST,
  // eslint-disable-next-line no-alert
  useApi: (client: AxiosInstance) => client.get(url!, { headers: { Authorization: oktaToken } }).catch(() => alert('Failed to authenticate, please refresh the page')),
});

// TODO: Remove when going live. Added this for testing and dev purposes
export const setStreamAuthInfo = (token: string, id: string) => ({
  type: StreamActionTypes.GET_STREAM_TOKEN_SUCCESS,
  payload: {
    token,
    id,
  },
});
