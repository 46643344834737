import { AnyAction } from 'redux';
import { StreamState } from '../../interfaces';
import { StreamActionTypes } from '../actionTypes';

export default (
  state = {} as StreamState,
  { type, payload }: AnyAction = { type: StreamActionTypes.DEFAULT },
) => {
  switch (type) {
    case StreamActionTypes.GET_STREAM_TOKEN_SUCCESS:
      return {
        ...state,
        token: payload.token,
        id: payload.id,
        name: payload.name,
      };
    default:
      return state;
  }
};
