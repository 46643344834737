import { AnyAction } from 'redux';
import { ConfigsState } from '../../interfaces';
import { ConfigsActionTypes } from '../actionTypes';

const initialState: ConfigsState = {};

/**
 * In order to fetch configs, you should use the `useConfigs` hook available in `src/lib/hooks`/
 * TODO: SPORT-2926 Deprecate store configs
 */
export default (
  state = initialState,
  { type, payload }: AnyAction = { type: ConfigsActionTypes.DEFAULT },
) => {
  switch (type) {
    case ConfigsActionTypes.GET_API_CONFIG_SUCCESS:
      return {
        ...state,
        api: payload,
      };
    case ConfigsActionTypes.GET_LANGUAGE_CONFIG_SUCCESS:
      return {
        ...state,
        language: payload,
      };
    case ConfigsActionTypes.GET_TIME_CONFIG_SUCCESS:
      return {
        ...state,
        cageTime: payload,
      };
    default:
      return state;
  }
};
