import React from 'react';
import styled from 'styled-components';
import { StreamMessage } from 'stream-chat-react';
import { CloseIcon, MenuIcon } from '../../assets';
import BackIcon from '../../assets/BackIcon';
import logger from '../../services/logger';
import BanAction from './Actions/BanAction';
import { emptyFunction } from '../../utils/common';
import ActionMenu from './Actions/ActionMenu';
import DeleteAction from './Actions/DeleteAction';
import useUserBanStatus from '../../hooks/useUserBanStatus';

export enum CustomActions {
  BAN = 'BAN',
  DELETE = 'DELETE',
}

const MenuIconDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: lightgray;
  border-radius: 1.25rem;
  position: absolute;
  right: 0;
  top: 30%;
  padding: 2px 8px;
  min-width: 20px;
  min-height: 5px;
  transform: translate(-50%, -50%);

  .action-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 100%;
    height: 1.25rem;

    &:hover {
      svg {
        fill: ${({ theme }) => theme.colors.neutral_400};
      }
    }
  }

  .actions-container,
  .confirm-action-container {
    background: lightgray;
    border-radius: 0.5625rem;
    position: absolute;
    transform: translate(-50%, -50%);
    overflow: hidden;
    transition: all 0.2s ease;
    display: flex;
    flex-direction: column;
    text-align: left;
    white-space: nowrap;
    width: auto;
    z-index: 99;


    .action-item {
      list-style: none;
      text-align: left;
      padding: 1rem;
      transition: all 0.2s ease-in;

      &:hover {
        background: #e6e6e6;
        cursor: pointer;
      }
    }
  }

  .confirm-action-container {
    padding: 0.25rem 0.5rem;
    min-width: 250px;
    button {
      background: transparent;
      border: 1px solid black;
      cursor: pointer;
      width: fit-content;
    }

    input {
      padding: 0.25rem;
    }

    .back-btn {
      border: none;

      &:hover {
        color: ${({ theme }) => theme.colors.primary_500};
      }
    }

    .action-error{
      font-size: ${({ theme }) => theme.fontSize.text_sm};
      color: ${({ theme }) => theme.colors.error_300};
    }

    .action-confirm-btn {
      background-color: ${({ theme }) => theme.colors.primary_300};
      border: 2px solid ${({ theme }) => theme.colors.primary_300};
      color: ${({ theme }) => theme.colors.white};
      border-radius: 2rem;
      padding: 0.15rem 0.5rem;
      margin: 0.5rem 0;
      width: fit-content;
      cursor: pointer;
      transition: all 0.2s ease-in;
      float: right;

      &:hover {
        background-color: ${({ theme }) => theme.colors.primary_400};
        border: 2px solid ${({ theme }) => theme.colors.primary_400};
      }
    }

    .action-confirm-btn[disabled] {
      background-color: ${({ theme }) => theme.colors.neutral_500};
      border: 2px solid ${({ theme }) => theme.colors.neutral_500};
      cursor: auto;
      &:hover{
        background-color: ${({ theme }) => theme.colors.neutral_500};
      }
    }
  }
`;

export type CustomMessageActionsProps = {
  message: StreamMessage;
  isActive: boolean;
}

// TODO the rendering of this component and creation of functions is not optimized,
// a lot of code that didn't need to run on every cycle is currently running
// as optimization on the mod chat is not a high priority, will leave this for now
export default function CustomMessageActions(props: CustomMessageActionsProps) {
  const { isActive, message: { user, id: messageId } } = props;
  const [isPopupOpen, setIsPopupOpen] = React.useState(false);
  const [action, setAction] = React.useState<CustomActions | null>(null);
  const [hasActionError, setHasActionError] = React.useState(false);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const {
    isBanned, isModUser,
    isProcessing: checkingUserBanStatus,
  } = useUserBanStatus(user!.id, isPopupOpen);

  const handleAction = async (event: Event, executeAction: Function) => {
    try {
      setHasActionError(false);
      setIsProcessing(true);
      await executeAction(event);
      setIsPopupOpen(false);
      setAction(null);
    } catch (err) {
      logger.error(`${action} operation failed`, { err, messageId, user });
      setHasActionError(true);
    } finally {
      setIsProcessing(false);
    }
  };

  const togglePopup = () => {
    if (isPopupOpen) {
      setAction(null);
    }
    setIsPopupOpen(!isPopupOpen);
  };

  if ((!isActive && !isPopupOpen) || user?.role === 'admin') return null;

  const renderAction = () => {
    switch (action) {
      case CustomActions.BAN:
        return (
          <BanAction
            user={user!}
            isUserBanned={isBanned}
            handleAction={handleAction}
            isProcessing={isProcessing}
          />
        );
      case CustomActions.DELETE:
        return (
          <DeleteAction
            messageId={messageId}
            handleAction={handleAction}
            isProcessing={isProcessing}
          />
        );
      default:
        return null;
    }
  };

  return (
    <MenuIconDiv>
      <div
        className="action-icon"
        role="button"
        tabIndex={0}
        onClick={() => togglePopup()}
        onKeyDown={emptyFunction}
      >
        {isPopupOpen ? <CloseIcon /> : <MenuIcon />}
      </div>
      {(isPopupOpen && !action)
        && (
          <ActionMenu
            setAction={setAction}
            isUserBanned={isBanned}
            isModUser={isModUser}
            isProcessing={checkingUserBanStatus}
          />
        )}
      {/* Ban/Unban Action Logic */}
      {(isPopupOpen && action) && (
        <div className={`confirm-action-container action-${action}`}>
          <button
            aria-label="Back Button"
            className="back-btn"
            onClick={() => setAction(null)}
            type="button"
          >
            <BackIcon />
          </button>
          {renderAction()}
          {hasActionError && (
            <span className="action-error">Server error - try again</span>
          )}
        </div>
      )}
    </MenuIconDiv>
  );
}
