import { AxiosInstance } from 'axios';
import { ConfigsActionTypes } from '../actionTypes';

/**
 * @deprecated The method should not be used
 * In order to fetch configs, you should use the `useConfigs` hook available in `src/lib/hooks`/
 * TODO: SPORT-2926 Deprecate store configs
 */
export const getApiConfig = () => ({
  type: ConfigsActionTypes.GET_API_CONFIG_REQUEST,
  useApi: (client: AxiosInstance) => client.get('/conf/api.json'),
});

/**
 * @deprecated The method should not be used
 * In order to fetch configs, you should use the `useConfigs` hook available in `src/lib/hooks`/
 * TODO: SPORT-2926 Deprecate store configs
 */
export const getLanguageConfig = () => ({
  type: ConfigsActionTypes.GET_LANGUAGE_CONFIG_REQUEST,
  useApi: (client: AxiosInstance) => client.get('/conf/language.json'),
});

/**
 * @deprecated The method should not be used
 * In order to fetch configs, you should use the `useConfigs` hook available in `src/lib/hooks`/
 * TODO: SPORT-2926 Deprecate store configs
 */
export const getTimeConfig = (apiUrl: string, cage: string) => ({
  type: ConfigsActionTypes.GET_TIME_CONFIG_REQUEST,
  useApi: (client: AxiosInstance) => client.get(`${apiUrl}cage/${cage}/time`),
});
