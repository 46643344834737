import React from 'react';
import styled from 'styled-components';

const ChannelEmptyStateWrapper = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: flex-end;
  margin-left: 20px;
  padding-bottom: 20px;
`;

function ChannelEmptyState() {
  return (
    <ChannelEmptyStateWrapper>
      No messages in this channel yet.
    </ChannelEmptyStateWrapper>
  );
}

export default ChannelEmptyState;
