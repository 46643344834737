export const MAX_EMOJIS_DISPLAYED = 5;

export function getReactionsToRender(
  reactions: { [key: string]: number },
  ownReaction: string | undefined = undefined,
) {
  const sortedReactions = Object.entries(reactions).sort((a, b) => b[1] - a[1]);

  if (ownReaction) {
    let ownReactionIdx = -1;
    sortedReactions.forEach((sortedReaction, i) => {
      if (sortedReaction.includes(ownReaction)) {
        ownReactionIdx = i;
      }
    });

    if (ownReactionIdx > -1) {
      const splicedReaction = sortedReactions.splice(ownReactionIdx, 1);
      sortedReactions.unshift(splicedReaction[0]);
    }
  }

  if (sortedReactions.length > MAX_EMOJIS_DISPLAYED) {
    return sortedReactions
      .slice(0, MAX_EMOJIS_DISPLAYED);
  }

  return sortedReactions;
}
