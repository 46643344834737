import React, { useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import { useChatContext } from 'stream-chat-react';

import styled from 'styled-components';
import cages from '../../cages.json';

import { CloseCreateChannel } from '../../assets';
import { useCustomChatContext } from '../../contexts/CustomChatContext';
import { CageOptions, CageSelectRef } from '../../interfaces';
import { logger } from '../../services';
import { createCageName } from '../../utils/common';

const CreateChannelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .create-channel__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 62px;
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);
    padding-right: 20px;
    padding-bottom: 4px;
  }

  .create-channel__header p {
    font-family: Helvetica Neue, sans-serif;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #2c2c30;
    margin-left: 20px;
  }

  .create-channel__header svg {
    cursor: pointer;
  }
`;

const CreateChannelContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 32px;
  width: 100%;

  .cageSelector {
    width: 500px
  }

`;

const Input = styled.input`
  margin: 20px 0;
  border-radius: 8px;
  padding: 10px;
  width: 475px;
  
  &:focus-visible {
    outline-color: #005fff;
  }
`;

const SubmitBtn = styled.button`
  background-color: #005fff;
  color: #fff;
  border: none;
  border-radius: 8px;
  font-size: medium;
  height: 40px;
  width: 500px;
`;

function CreateChannel() {
  const { setIsCreatingChannel } = useCustomChatContext();
  const { client } = useChatContext();
  const [cageOptions, setCageOptions] = useState<CageOptions[]>();
  const selectRef = useRef<CageSelectRef>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setCageOptions(cages.dev.map((cage) => ({
      value: cage.cageCode,
      label: `${cage.cageCode} - ${cage.shortName}`,
      cage,
    })));
  }, []);

  const createChannel = async (event: { preventDefault: () => void; }) => {
    event.preventDefault();

    try {
      selectRef.current?.getValue().forEach(async ({ cage }) => {
        const channelName = `${createCageName(cage.cageCode, inputRef.current?.value as string)}`;
        logger.info(`Trying to create channel ${channelName}`);
        const newChannel = await client.channel('livestream', channelName, {
          name: inputRef.current?.value,
          cageCode: cage.cageCode,
          codeName: cage.name,
          codeShortName: cage.shortName, // TODO hardcoded for WV for now
        });

        await newChannel.watch();
      });

      setIsCreatingChannel(false);
    } catch (err) {
      logger.error(err);
    }
  };

  return (
    <CreateChannelWrapper>
      <div className="create-channel__header">
        <p>Create a New Channel</p>
        <CloseCreateChannel />
      </div>
      <CreateChannelContent>
        <Select placeholder="Select cages to create channel for..." options={cageOptions} ref={selectRef} isMulti className="cageSelector" />
        <Input ref={inputRef} placeholder="channel-name (no spaces)" />
        <SubmitBtn onClick={createChannel}>Create Channel</SubmitBtn>
      </CreateChannelContent>
    </CreateChannelWrapper>
  );
}

export default CreateChannel;
