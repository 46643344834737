import React from 'react';
import {
  useChannelStateContext, Message, MessageText, useChatContext,
} from 'stream-chat-react';
import styled from 'styled-components';

import { useDispatch } from 'react-redux';
import { CloseThreadIcon, EditIcon } from '../../assets';
import { setPinMessageOverlay } from '../../store/actions/overlay';
import { setPinnedMessage } from '../../store/actions/pinnedMessage';
import { CustomFormatMessageResponse } from '../../interfaces/PinMessage';
import DeleteIcon from '../../assets/DeleteIcon';

const PinnedMessageListWrapper = styled.div`
  height: 100%;
  width: 300px;
  box-shadow: inset 1px 0px 0px rgba(0, 0, 0, 0.1);
  z-index: 1;
  background: #ffffff;
  overflow-x: hidden;

  .pinned-messages__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 62px;
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);
  }

  .pinned-messages__header-text {
    font-family: Helvetica Neue, sans-serif;
    font-weight: bold;
    font-size: 18px;
    margin-left: 20px;
    color: #2c2c30;
  }

  .pinned-messages__list {
    padding-top: 20px;
  }

  .pinned-messages__list .pinned-message {
    background: #fff !important;
    margin-left: 0.5px;
  }

  .pinned-messages__list .str-chat__message-simple__actions {
    display: none;
  }

  .pinned-messages__list .str-chat__message-pin-indicator {
    display: none;
  }

  .pinned-messages__list .str-chat__message-replies-count-button {
    display: none;
  }

  .pinned-messages__list .str-chat__message-team .str-chat__message-actions {
    display: none;
  }

  div.pinned-messages__header > button {
    background: #fff;
    margin: 0;
    padding: 0;
    margin-right: 20px;
    height: 35px;
    width: 35px;
    border-radius: 18px;
  }

  div.pinned-messages__header > button:active {
    background: #fff;
  }
`;

const IconButton = styled.button`
  background-color: white;
  border: none;
  cursor: pointer;
`;

const MessageContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;

function PinnedMessageList() {
  const dispatch = useDispatch();
  const { channel } = useChannelStateContext();
  const { client } = useChatContext();

  const onPinMessageClick = (message: CustomFormatMessageResponse) => {
    dispatch(setPinnedMessage(message));
    dispatch(setPinMessageOverlay());
  };

  const onDeletePinMessage = (message: CustomFormatMessageResponse) => {
    client.deleteMessage(message.id);
  };

  return (
    <PinnedMessageListWrapper>
      <div className="pinned-messages__header">
        <p className="pinned-messages__header-text">Pins</p>
        <CloseThreadIcon />
      </div>
      <div className="pinned-messages__list">
        {channel.state.pinnedMessages.map((message) => (
          <MessageContainer key={message.id}>
            <Message
              groupStyles={['single']}
              Message={MessageText}
            // eslint-disable-next-line react/jsx-no-duplicate-props
              message={message}
            />

            <IconButton type="button" onClick={() => onPinMessageClick(message)}>
              <EditIcon />
            </IconButton>
            <IconButton type="button" onClick={() => onDeletePinMessage(message)}>
              <DeleteIcon />
            </IconButton>
          </MessageContainer>
        ))}
      </div>
    </PinnedMessageListWrapper>
  );
}

export default PinnedMessageList;
