import React from 'react';
import { FaTimesCircle } from 'react-icons/fa';
import { useCustomChatContext } from '../contexts/CustomChatContext';

function CloseCreateChannel() {
  const { setIsCreatingChannel, setIsEditingChannel } = useCustomChatContext();

  return (
    <FaTimesCircle
      fill="var(--primary-color)"
      size={32}
      onClick={() => {
        if (setIsCreatingChannel) setIsCreatingChannel(false);
        if (setIsEditingChannel) setIsEditingChannel(false);
      }}
    />
  );
}

export default CloseCreateChannel;
