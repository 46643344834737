import { DefaultTheme, BrandColors, Typography } from 'styled-components';

export const BetriversColors: BrandColors = {
  primary_100: '#ecf4fb',
  primary_200: '#bdcfed',
  primary_250: '#9DB8E4',
  primary_300: '#2a64c5',
  primary_400: '#1e478c',
  primary_500: '#122a53',
};

// Sizes are duplicated as per design system, Typography Page
// https://www.figma.com/file/PZQ1PJpOZ7MOBjj3ibScES/%F0%9F%93%9C-Design-System-Documentation?node-id=403-33&t=NYRRrmdZkeuNyddf-0
export const FontSizeMobile: Typography = {
  h1: '32px',
  h2: '28px',
  h3: '24px',
  h4: '20px',
  h5: '18px',
  text_lg: '20px',
  text_md: '16px',
  text_sm: '14px',
  text_xs: '12px',
  text_xxs: '10px',
};

export const LineHeightMobile: Typography = {
  h1: '40px',
  h2: '36px',
  h3: '32px',
  h4: '28px',
  h5: '24px',
  text_lg: '24px',
  text_md: '24px',
  text_sm: '16px',
  text_xs: '16px',
  text_xxs: '12px',
};

const theme: DefaultTheme = {
  fontFamily: 'Helvetica Neue, sans-serif',
  emojiSize: {
    reactionList: 14,
    reactionSelector: 20,
    reactionDetails: 16,
  },
  iconBtn: {
    xxs: '12px',
    md: '20px',
    regular: '32px',
  },
  chatSettingItem: {
    padding: '14px 18px',
    height: '44px',
  },
  fontWeight: {
    regular: 400,
    medium: 500,
    bold: 700,
  },
  colors: {
    black: '#000',
    black_alpha_30: 'rgba(0, 0, 0, 0.3)',
    black_alpha_60: 'rgba(0, 0, 0, 0.6)',
    black_alpha_75: 'rgba(0, 0, 0, 0.75)',
    white: '#FFF',
    white_alpha_3: 'rgba(255, 255, 255, 0.03)',
    white_alpha_30: 'rgba(255, 255, 255, 0.3)',
    white_alpha_60: 'rgba(255, 255, 255, 0.6)',
    white_alpha_75: 'rgba(255, 255, 255, 0.75)',
    neutral_100: '#eff1f4',
    neutral_200: '#cdd5dc',
    neutral_250: '#9FB0BF',
    neutral_300: '#7d92a5',
    neutral_400: '#5d778f',
    neutral_500: '#425466',
    neutral_600: '#33414f',
    neutral_700: '#1A2228',
    secondary_100: '#fff8e8',
    secondary_200: '#fee8b8',
    secondary_300: '#fdb61b',
    secondary_400: '#b48113',
    secondary_500: '#6A4C0B',
    success_100: '#e7f8ef',
    success_200: '#b4e8cc',
    success_300: '#0db45c',
    success_400: '#098041',
    success_500: '#054c27',
    warning_100: '#fffae6',
    warning_200: '#ffeeb0',
    warning_300: '#ffc800',
    warning_400: '#b58e00',
    warning_500: '#6b5400',
    error_100: '#fdeded',
    error_200: '#f8c8c8',
    error_300: '#e84e4e',
    error_400: '#a53737',
    error_500: '#612121',
    info_100: '#ebf2fd',
    info_200: '#c2d6f9',
    info_300: '#3a7ced',
    info_400: '#2958a8',
    info_500: '#183464',
    // Set Betrivers as default brand for now
    ...BetriversColors,
  },
  // Set Mobile typography sizes as default, since we are mobile first
  fontSize: {
    ...FontSizeMobile,
  },
  lineHeight: {
    ...LineHeightMobile,
  },
  buttonSize: {
    lg: '48px',
    md: '40px',
    sm: '32px',
    xs: '24px',
  },
};

export const screenSizes = {
  screenXxxs: 300,
  screenXxs: 370,
  screenXs: 480,
  screenSm: 768,
  screenMd: 992,
  screenLg: 1200,
  screenXlg: 1400,
  screenHd: 1920,
  screenXhd: 2560,
  screenSuhd: 3440,
  screenUhd: 3840,
};

export default theme;
