import React from 'react';
import {
  DeliveredCheckIcon,
  LoadingIndicator,
  useMessageContext,
} from 'stream-chat-react';
import styled from 'styled-components';
import { MessageStatus } from '../../interfaces';

const MessageStatusIndicatorWrapper = styled.span`
    position: relative;
    line-height: 1;
    align-items: center;
    z-index: 11;
`;

function UnMemoizedMessageStatusIndicator() {
  const {
    isMyMessage,
    lastReceivedId,
    message,
  } = useMessageContext('MessageStatusIndicator');

  if (!isMyMessage() || message.type === 'error') return null;

  const sending = message.status === MessageStatus.sending;
  const delivered = message.status === MessageStatus.received && message.id === lastReceivedId;

  return (
    <MessageStatusIndicatorWrapper>
      {sending && <LoadingIndicator />}
      {delivered && <DeliveredCheckIcon />}
    </MessageStatusIndicatorWrapper>
  );
}

const MessageStatusIndicator = React.memo(UnMemoizedMessageStatusIndicator);
export default MessageStatusIndicator;
