import { createReducer } from '@reduxjs/toolkit';
import { ChannelTabsState } from '../../interfaces';
import { channelTabsActions } from '../actions';

const initialState: ChannelTabsState = {
  channels: [],
};

export default createReducer(initialState, (builder) => {
  builder
    .addCase(channelTabsActions.openChannel, (state, { payload: channel }) => {
      if (!state.channels.find((existingChannel) => existingChannel.id === channel.id)) {
        channel.watch();
        state.channels.push(channel);
      }
    })
    .addCase(channelTabsActions.closeChannel, (state, { payload: channel }) => {
      channel.stopWatching();
      state.channels = state.channels.filter((c) => c.id !== channel.id);
    });
});
