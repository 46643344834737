import React, { useState } from 'react';
import { useChatContext } from 'stream-chat-react';

import styled from 'styled-components';

import { CloseCreateChannel } from '../../assets';
import { useCustomChatContext } from '../../contexts/CustomChatContext';

const EditChannelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  .edit-channel__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 62px;
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);
    padding-right: 20px;
  }

  .edit-channel__header p {
    font-family: Helvetica Neue, sans-serif;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #2c2c30;
    margin-left: 20px;
  }

  .edit-channel__header svg {
    cursor: pointer;
  }

  .channel-name-input__wrapper {
    display: flex;
    flex-direction: column;
    height: 169px;
    padding-left: 20px;
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);
  }

  .channel-name-input__wrapper p {
    font-family: Helvetica Neue, sans-serif;
    font-size: 16px;
    line-height: 120%;
    color: #2c2c30;
    margin-top: 30px;
  }

  .channel-name-input__wrapper input {
    font-family: Helvetica Neue, sans-serif;
    font-size: 18px;
    color: rgba(0, 0, 0);
    height: 50px;
    width: 540px;
    background: #f7f6f8;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    border-radius: 8px;
    padding-left: 16px;
  }

  .channel-name-input__wrapper input:focus {
    border: 1px solid var(--primary-color);
    outline: none;
  }

  .channel-name-input__wrapper input::placeholder {
    font-weight: 300;
    color: rgba(0, 0, 0, 0.5);
  }

  .edit-channel__button-wrapper {
    height: 82px;
    background: #f7f6f8;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-bottom-right-radius: 16px;
  }

  .edit-channel__button-wrapper p {
    background: var(--primary-color);
    font-family: Helvetica Neue, sans-serif;
    font-weight: bold;
    font-size: 18px;
    padding: 10px 20px;
    color: #ffffff;
    margin-right: 30px;
    border-radius: 8px;
    cursor: pointer;
  }
`;

type InputProps = {
  channelName: string;
  setChannelName: (value: React.SetStateAction<string>) => void;
};

function ChannelNameInput(props: InputProps) {
  const { channelName = '', setChannelName } = props;

  const handleChange = (event: { preventDefault: () => void; target: { value: string; }; }) => {
    event.preventDefault();
    setChannelName(event.target.value);
  };

  return (
    <div className="channel-name-input__wrapper">
      <p>Name</p>
      <input onChange={handleChange} placeholder="channel-name" type="text" value={channelName} />
    </div>
  );
}

function EditChannel() {
  const { setIsEditingChannel } = useCustomChatContext();

  const { channel } = useChatContext();

  const [channelName, setChannelName] = useState<string>(
    channel?.data?.name || (channel?.data?.id as string),
  );

  const updateChannel = async (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.preventDefault();

    const nameChanged = channelName !== (channel?.data?.name || channel?.data?.id);

    if (nameChanged) {
      await channel?.updatePartial({ set: { name: channelName } });
    }

    setChannelName('');
    setIsEditingChannel(false);
  };

  return (
    <EditChannelWrapper>
      <div className="edit-channel__header">
        <p>Edit Channel</p>
        <CloseCreateChannel />
      </div>
      <ChannelNameInput {...{ channelName, setChannelName }} />
      <div
        onKeyDown={() => null}
        role="button"
        tabIndex={0}
        className="edit-channel__button-wrapper"
        onClick={updateChannel}
      >
        <p>Save Changes</p>
      </div>
    </EditChannelWrapper>
  );
}

export default EditChannel;
