import React from 'react';
import styled from 'styled-components';
import { useChatContext } from 'stream-chat-react';
import { useDispatch } from 'react-redux';
import { useOktaAuth } from '@okta/okta-react';
import { setBanControlOverlay, setOverlay, setPinMessageOverlay } from '../../store/actions/overlay';
import { logger } from '../../services';
import { OverlayType } from '../../interfaces';
import { SearchIcon } from '../../assets';
import { useCustomChatContext } from '../../contexts/CustomChatContext';
import UsernameSection from './UsernameSection';

const HeaderBtn = styled.button`
  background-color: rgba(0, 0, 0, 0.4);
  color: #fff;
  font-family: Helvetica Neue, sans-serif;
  border: none;
  border-radius: 8px;
  height: 30px;
  margin-right: 8px;
  margin-top: 10px;
`;

// Temp variable to disable functionality for Phase 0
const showLogout = false;

function ChatActions() {
  const dispatch = useDispatch();
  const { oktaAuth } = useOktaAuth();
  const { client } = useChatContext();
  const { isReadOnly } = useCustomChatContext();

  const onSearchClick = () => {
    dispatch(setOverlay(OverlayType.MessageSearchFilter));
  };

  const onPinMessageClick = () => {
    dispatch(setPinMessageOverlay());
  };

  const onBanControlClick = () => {
    dispatch(setBanControlOverlay());
  };

  const onLogoutClick = async () => {
    try {
      client?.disconnectUser();
      await oktaAuth.signOut({ postLogoutRedirectUri: window.location.origin });
    } catch (err) {
      logger.error(err);
    }
  };

  return (
    <>
      <UsernameSection />
      <HeaderBtn onClick={onSearchClick}>
        {'Search messages '}
        <SearchIcon />
      </HeaderBtn>
      {!isReadOnly && (
        <HeaderBtn onClick={onBanControlClick}>BanControl</HeaderBtn>
      )}
      {!isReadOnly && <HeaderBtn onClick={onPinMessageClick}>Pin message</HeaderBtn>}
      {showLogout && (
        <HeaderBtn onClick={onLogoutClick}>Logout</HeaderBtn>
      )}
    </>
  );
}

export default ChatActions;
