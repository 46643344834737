import React, {
  PropsWithChildren,
  useCallback,
  useContext, useEffect, useMemo, useState,
} from 'react';
import { useChatContext, usePaginatedChannels } from 'stream-chat-react';
import { ChannelOptions, RsiChatGenerics } from '../interfaces';
import { filtersDefault, optionsStateless, sortDefault } from '../utils';

export type ChannelsContextValue = {
  channels: ChannelOptions[];
  setActiveChannelById: (cid: string) => void;
};

const ChannelsContext = React.createContext<ChannelsContextValue | undefined>(undefined);
export function ChannelsProvider(props: PropsWithChildren) {
  const { children } = props;
  const [channelOptions, setChannelOptions] = useState<ChannelOptions[]>([]);

  const { client, setActiveChannel } = useChatContext<RsiChatGenerics>();
  const { channels: availableChannels } = usePaginatedChannels<RsiChatGenerics>(
    client,
    filtersDefault,
    sortDefault,
    optionsStateless,
    () => { },
  );

  const setActiveChannelById = useCallback((cid: string) => {
    const channelToSelect = availableChannels
      .filter((channel) => channel.cid === cid);

    if (channelToSelect.length) {
      setActiveChannel(channelToSelect[0]);
    }
  }, [availableChannels, setActiveChannel]);

  useEffect(() => {
    if (availableChannels.length) {
      setChannelOptions(availableChannels.map((channel) => ({
        value: channel.cid,
        label: `${channel.data?.cageName} - ${channel.data?.name}`,
        channel,
      })));
    }
  }, [availableChannels]);

  const ChannelsContextValue: Required<ChannelsContextValue> = useMemo(() => ({
    channels: channelOptions,
    setActiveChannelById,
  }), [channelOptions, setActiveChannelById]);

  return (
    <ChannelsContext.Provider value={ChannelsContextValue}>
      {children}
    </ChannelsContext.Provider>
  );
}

export const useChannelsContext = () => {
  const contextValue = useContext(ChannelsContext);
  return contextValue as Required<ChannelsContextValue>;
};

export default useChannelsContext;
