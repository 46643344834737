import { createReducer } from '@reduxjs/toolkit';
import { OverlayState, OverlayType } from '../../interfaces';
import { setOverlay } from '../actions/overlay';

const initialState: OverlayState = { type: OverlayType.None };

export default createReducer(initialState, (builder) => {
  builder
    .addCase(setOverlay, (state, { payload: { overlayType, data } }) => {
      state.type = overlayType;
      state.data = data;
    });
});
