import React from 'react';
import styled from 'styled-components';
import { AddChannel } from '../../assets';
import { ChannelOptions } from '../../interfaces';
import CustomChannelListItem from './CustomChannelListItem';

const CustomChannelListWrapper = styled.div`

  width: 100%;

  .channel-list__message {
    color: #ffffff;
    padding: 0 16px;
  }

  .channel-list__message.loading {
    height: 115px;
  }

  .channel-list__header {
    padding: 0 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .channel-list__header svg {
    cursor: pointer;
  }

  .channel-list__header__title {
    font-family: Helvetica Neue, sans-serif;
    font-size: 13px;
    line-height: 16px;
    height: 16px;
    color: rgba(255, 255, 255, 0.66);
    margin-bottom: 10px;
  }`;

// Temp variable to disable functionality for Phase 0
const showAddChannel = false;

interface CustomChannelListProps {
  channels: ChannelOptions[]
}

function CustomChannelList({ channels }: Readonly<CustomChannelListProps>) {
  return (
    <CustomChannelListWrapper>
      <div className="channel-list__header">
        <p className="channel-list__header__title">
          Channels
        </p>
        {showAddChannel && <AddChannel />}
      </div>
      {channels.map(({ channel }) => (
        <CustomChannelListItem key={channel.id} channel={channel} />
      ))}
    </CustomChannelListWrapper>
  );
}

export default React.memo(CustomChannelList);
