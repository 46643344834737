import styled, { css } from 'styled-components';

interface RowProps {
  gap?: string
  alignCenter?: boolean
  flexStart?:boolean
}

const Row = styled.div<RowProps>`
  display: flex;
  flex-wrap: wrap;
  margin: 0 ${({ gap }) => gap || '15px'};
  ${({ alignCenter }) => alignCenter
  && css`
      align-items: center;
    `}

  ${({ flexStart }) => flexStart
  && css`
      justify-content: flex-start;
    `}
`;

export default Row;
