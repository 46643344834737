import { useChannelStateContext, useChatContext } from 'stream-chat-react';

import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { ChannelInfo, PinIcon } from '../../assets';
import { useCustomChatContext } from '../../contexts/CustomChatContext';
import { closeChannel } from '../../store/actions/channelTabs';
import { AlternateNames, RsiChatGenerics } from '../../interfaces';
import DeleteChannel from '../../assets/DeleteChannel';
import deleteChannel from '../../store/actions/channelOperations';
import useAlternateName from '../../hooks/useAlternateName';
import logger from '../../services/logger';
import { MAX_NICKNAME_LENGTH } from '../../utils/common';

const ChannelHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);
  color: #858688;
  z-index: 1;
  cursor: move;
`;

const ChannelHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  width: 100%;
`;

const ChannelNameCtn = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  flex-direction: row;
  justify-content: space-between;
`;

const ChannelNameP = styled.div`
  font-family: Helvetica Neue, sans-serif;
  font-weight: bold;
  font-size: 18px;
  color: #2c2c30;
  margin: 0 8px 0 0;
`;

const ChannelEditSpan = styled.span`
  display: flex;
  cursor: pointer;
`;

const ChannelInfoCtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ChannelDeleteSpan = styled.span`
  display: flex;
  cursor: pointer;
  margin-left: 4px;
`;

const ChannelInfoP = styled.p`
  font-family: Helvetica Neue, sans-serif;
  font-size: 14px;
`;

const PinWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    margin-left: 16px;
    margin-right: 4px;
  }
`;

const PinText = styled.p`
  font-family: Helvetica Neue, sans-serif;
  font-size: 14px;
`;

const ChannelNicknameCtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const ChannelNicknameP = styled.p`
  font-family: Helvetica Neue, sans-serif;
  font-size: 14px;
  margin: 0;
`;

const ChannelNicknameSpan = styled.span`
  font-weight: bold;
`;

const CloseBtn = styled.button`
  background: transparent;
  cursor: pointer;
  font-size: 20px;
  padding: 4px;
  font-weight: bold;
  border: 1px solid darkgray;
`;

const FlexContainer = styled.div`
  display: flex;
`;

const VeritcalFlexContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ErrorP = styled.p`
  color: red;
  font-size: 11px;
  margin: 0;
  padding: 0;
`;

const getWatcherText = (watcherCount?: number) => {
  if (!watcherCount) { return 'No users online'; }
  if (watcherCount === 1) { return '1 user online'; }
  return `${watcherCount} users online`;
};

// Temp variables to disable functionality for Phase 0
const showEditDelete = false;

function ChannelHeader() {
  const { setIsEditingChannel, setIsPinsOpen, isReadOnly } = useCustomChatContext();
  const dispatch = useDispatch();
  const { client } = useChatContext();

  const [nickname, setNickname] = useState('');
  const [error, setError] = useState(false);

  const { channel, watcher_count: watcherCount } = useChannelStateContext<RsiChatGenerics>();

  const channelName = `${channel?.data?.cageName || ''} ${channel?.data?.cageName ? '-' : ''} ${channel?.data?.name}`;

  const alternateName = useAlternateName(client.user);

  const closeChannelTab = () => {
    dispatch(closeChannel(channel));
  };

  const deleteChannelClick = (event: any) => {
    event.stopPropagation();
    dispatch(deleteChannel(channel));
  };

  const upsertUser = () => {
    if (client.user) {
      let alternateNames = {} as AlternateNames;
      if (client.user?.alternateNames) {
        const typedAlternateNames = client.user.alternateNames as AlternateNames;
        alternateNames = { ...typedAlternateNames };
      }
      alternateNames[channel.cid] = nickname;
      const payload = {
        id: client.user?.id,
        set: {
          alternateNames,
        },
      };
      logger.info('Updating alternate names', payload);
      client.partialUpdateUser(payload);
      setNickname('');
    }
  };

  const onNicknameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length > MAX_NICKNAME_LENGTH) {
      setError(true);
    } else {
      setError(false);
      setNickname(event.target.value);
    }
  };

  return (
    <ChannelHeaderContainer className="drag-handle">
      <ChannelHeaderWrapper>
        <ChannelNameCtn>
          <ChannelNameP>{channelName}</ChannelNameP>
          {showEditDelete
            && (
              <>
                <ChannelEditSpan
                  role="button"
                  tabIndex={0}
                  onKeyDown={() => null}
                  style={{ display: 'flex' }}
                  onClick={() => setIsEditingChannel(true)}
                >
                  <ChannelInfo />
                </ChannelEditSpan>
                <ChannelDeleteSpan>
                  <DeleteChannel onClick={deleteChannelClick} />
                </ChannelDeleteSpan>
              </>
            )}
          <CloseBtn onClick={closeChannelTab} type="button">X</CloseBtn>
        </ChannelNameCtn>
        <ChannelInfoCtn>
          <FlexContainer>
            <ChannelInfoP>{getWatcherText(watcherCount)}</ChannelInfoP>
            <PinWrapper
              role="button"
              tabIndex={0}
              onKeyDown={() => null}
              onClick={() => {
                setIsPinsOpen((prevState) => !prevState);
              }}
            >
              <PinIcon />
              <PinText>Pins</PinText>
            </PinWrapper>
          </FlexContainer>
        </ChannelInfoCtn>
        {!isReadOnly && (
          <ChannelNicknameCtn className="nickname-container">
            <ChannelNicknameP>
              Nickname:
              {' '}
              <ChannelNicknameSpan>{alternateName}</ChannelNicknameSpan>
            </ChannelNicknameP>
            <VeritcalFlexContainer>
              <FlexContainer>
                <input placeholder="Change Nickname" value={nickname} onChange={(event) => onNicknameChange(event)} maxLength={MAX_NICKNAME_LENGTH} />
                <button onClick={upsertUser} type="button">save</button>
              </FlexContainer>
              {error
                && (
                  <ErrorP>
                    {`Nickname needs to be less than ${MAX_NICKNAME_LENGTH} characters`}
                  </ErrorP>
                )}
            </VeritcalFlexContainer>
          </ChannelNicknameCtn>
        )}

      </ChannelHeaderWrapper>
    </ChannelHeaderContainer>
  );
}

export default ChannelHeader;
