import { toRelativeUrl } from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';
import React, { useEffect } from 'react';
import { logger } from '../../services';

function RequireAuth({ children }: { children: JSX.Element }) {
  const { authState, oktaAuth, _onAuthRequired } = useOktaAuth();
  const pendingLogin = React.useRef(false);

  useEffect(() => {
    const handleLogin = async () => {
      if (pendingLogin.current) {
        return;
      }

      logger.info('Starting Okta login');

      pendingLogin.current = true;

      const originalUri = toRelativeUrl(window.location.href, window.location.origin);
      oktaAuth.setOriginalUri(originalUri);
      if (_onAuthRequired) {
        logger.info('Okta _onAuthRequired flow');
        await _onAuthRequired(oktaAuth);
      } else {
        logger.info('Okta signInWithRedirect flow');
        await oktaAuth.signInWithRedirect();
      }
    };

    if (!authState) {
      return;
    }

    if (authState.isAuthenticated) {
      pendingLogin.current = false;
      return;
    }

    // Start login if app has decided it is not logged in and there is no pending signin
    if (!authState.isAuthenticated) {
      handleLogin().catch((err) => {
        logger.error(err);
      });
    }
  });

  return children;
}

export default RequireAuth;
