import styled from 'styled-components';

const SubmitBtn = styled.button`
  background-color: #005fff;
  color: #fff;
  border: none;
  border-radius: 8px;
  font-size: medium;
  height: 40px;
  align-self: stretch;
  cursor: pointer;

  &:disabled{
    opacity: 0.5;
  }
`;

export default SubmitBtn;
