/* eslint-disable camelcase */
import React, { useState } from 'react';
import { ChannelResponse, UserResponse } from 'stream-chat';
import styled from 'styled-components';
import Select, { MultiValue } from 'react-select';

import UnbanButton from './UnbanButton';
import useGetUsersByCageId from '../../hooks/useGetUsersByCageId';
import { ChannelOptions } from '../../interfaces';
import useChannelsContext from '../../contexts/ChannelsContext';
import SubmitBtn from '../elements/SubmitBtn';
import Col from '../elements/Col';
import Row from '../elements/Row';
import Input from '../elements/Input';
import Container from '../elements/Container';

const BanControlContainer = styled.div`
  height: auto;
  max-height: inherit;
  overflow-x: auto;
  background-color: white;
  table {
    table-layout: fixed;
    width: 100%;
    padding: 8px;
  }

  th, td {
    padding: 4px;
    text-align: start;
  }

  tbody tr:nth-child(odd) {
    background-color: ${({ theme }) => theme.colors.neutral_100};
  }

  thead th.col-1 {
    width: 10%;
  }

  thead th.col-2 {
    width: 20%;
  }

`;

type BanItem = {
  user: UserResponse;
  banned_by?: UserResponse;
  channel?: ChannelResponse;
  expires?: string;
  ip_ban?: boolean;
  reason?: string;
  timeout?: number;
};

function buildRow(banItem: BanItem) {
  const {
    user: { name, id }, banned_by, reason, expires, channel,
  } = banItem;

  let expireDateTime = 'N/A (Permanent)';
  if (expires) {
    const isoDateTime = new Date(expires);
    expireDateTime = isoDateTime.toLocaleString();
  }

  return (
    <tr key={`${id}_${channel?.cid}`}>
      <td>{name}</td>
      <td>{channel?.cid}</td>
      <td>{banned_by?.name}</td>
      <td>{reason}</td>
      <td>{expireDateTime}</td>
      <td>
        <UnbanButton channelId={channel?.cid ?? ''} userId={id} />
      </td>
    </tr>
  );
}

function BanControl() {
  const [playerName, setPlayerName] = useState('');
  const { channels } = useChannelsContext();
  const [selectedChannels, setSelectedChannels] = useState<ChannelOptions[]>([]);
  const {
    bannedUsers, isLoading, fetch, refetchBannedUsers,
  } = useGetUsersByCageId();

  const playerOrChannelSelected = playerName.length !== 0 || selectedChannels.length !== 0;

  const handleClick = () => {
    if (!playerOrChannelSelected) {
      return;
    }
    const channelCids = selectedChannels?.map(({ value }) => value);
    fetch({
      channelCids,
      playerName,
    });
  };

  const handleResetAndRefetch = () => {
    setSelectedChannels([]);
    setPlayerName('');
    refetchBannedUsers();
  };

  const resetSelectedChannelsAndRefetch = () => {
    setSelectedChannels([]);
    refetchBannedUsers();
  };

  const resetPlayerNameAndRefetch = () => {
    setPlayerName('');
    refetchBannedUsers();
  };

  const handleChangePlayerName = (e:React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length === 0) {
      resetPlayerNameAndRefetch();
      return;
    }
    setPlayerName(e.target.value);
  };

  const onChannelsChange = (options: MultiValue<ChannelOptions>) => {
    if (options.length === 0) {
      resetSelectedChannelsAndRefetch();
      return;
    }
    setSelectedChannels([...options]);
  };

  const renderTableBody = () => {
    if (isLoading) {
      return <tr><td>Users loading...</td></tr>;
    }
    if (bannedUsers.length === 0) {
      return <tr><td>No records</td></tr>;
    }
    return bannedUsers.map((item) => buildRow(item));
  };

  return (
    <BanControlContainer>
      <Container>
        <Row alignCenter>
          <Col col={4}>
            <Select
              placeholder="Select channel(s)"
              onChange={onChannelsChange}
              value={selectedChannels}
              options={channels}
              isMulti
            />
          </Col>
          <Col col={4}>
            <Input
              placeholder="Player name"
              type="text"
              onChange={handleChangePlayerName}
              value={playerName}
            />
          </Col>
          <Col col={4}>
            <Row alignCenter flexStart>
              <Col col={1}>
                <SubmitBtn disabled={!playerOrChannelSelected} onClick={handleClick}>
                  Search
                </SubmitBtn>
              </Col>
              {playerOrChannelSelected && (
              <Col col={1}>
                <SubmitBtn onClick={handleResetAndRefetch}>Reset</SubmitBtn>
              </Col>
              )}
            </Row>
          </Col>
        </Row>
      </Container>
      <table>
        <thead>
          <tr>
            <th className="col-1">User Name</th>
            <th className="col-1">Room</th>
            <th className="col-1">Banned By</th>
            <th className="col-2">Banned Reason</th>
            <th className="col-1">Expires At</th>
            <th className="col-1">Action</th>
          </tr>
        </thead>
        <tbody>
          {renderTableBody()}
        </tbody>
      </table>
    </BanControlContainer>
  );
}

export default BanControl;
