import React from 'react';
import { useChatContext } from 'stream-chat-react';
import styled from 'styled-components';
import { logger } from '../../../services';

const DeleteActionContainer = styled.div`
  min-width: 200px;
`;
const ConfirmText = styled.span`
  word-wrap: break-word;
  white-space: normal;
  font-size: ${({ theme }) => theme.fontSize.text_sm};
`;

interface DeleteActionProp {
  messageId: string;
  handleAction: Function;
  isProcessing: boolean;
}

export default function DeleteAction(
  { messageId, handleAction, isProcessing }: DeleteActionProp,
) {
  const { client } = useChatContext();
  const handleDelete = async () => {
    logger.info(`Deleting message ${messageId}`);
    await client.deleteMessage(messageId);
  };

  return (
    <DeleteActionContainer>
      <ConfirmText>
        Are you sure you want to delete this message?
      </ConfirmText>
      <br />
      <button
        className="action-confirm-btn"
        onClick={(e) => handleAction(e, handleDelete)}
        type="button"
        disabled={isProcessing}
      >
        {isProcessing ? 'Processing..' : 'Confirm'}
      </button>
    </DeleteActionContainer>
  );
}
