import { ChannelResponse } from 'stream-chat';
import { StreamMessage } from 'stream-chat-react';
import { AlternateNames, RsiChannel, RsiChatGenerics } from '../interfaces';

export const CHANNEL_TYPE = 'livestream';

export const JUMP_TO_MESSAGE_EVENT = '[COMT]jumpToMessage';
export const MAX_NICKNAME_LENGTH = 28;
const ANONYMOUS = 'anonymous';
export const TRIVIA = 'TRIVIA';
export const createCageName = (cageCode: number | string | undefined, channelName: string) => (cageCode ? `${cageCode}-${channelName}` : channelName);

/**
 * Checks if the StreamMessage is a TRIVIA event.
 * @param message StreamMessage from chat
 * @returns True if the message is a TRIVIA event, otherwise false
 */
const isTriviaEvent = (message: StreamMessage<RsiChatGenerics>):
  boolean => message.feedEvent?.gameCode === TRIVIA;

/**
 * This method checks if message has custom field with moderator nickname. If it is present,
 * message is from moderator and the name was changed from original username.
 * When message is sent, it does not have the custom field with a moderator nickname.
 * Hence, we try to find the moderator nickname from alternateNames object
 * with a help of channel id(cid). Player messages DO NOT have this field, so their
 * messages will always render with user.name. If it's a moderator
 * message but name was not changed, use regular user.name
 * @param message StreamMessage from chat
 * @param cid Channel id
 * @returns display name that is rendered with the message
 */
export const getDisplayName = (
  message: StreamMessage<RsiChatGenerics>,
  cid?: string,
): string => {
  if (isTriviaEvent(message)) {
    return TRIVIA;
  }

  if (message.modNickname) {
    return message.modNickname;
  }

  if (cid) {
    // try to find a moderator nickname in alternateNames
    const moderatorNickname = message.user?.alternateNames as AlternateNames;
    // modNickname could be undefined, which would cause the moderator nickname
    // to "flash" from the moderator's username to their nickname. To prevent it, we try to
    // access the nickname from alternateNames
    if (moderatorNickname?.[cid]) {
      return moderatorNickname?.[cid];
    }
  }

  return message?.user?.name ?? ANONYMOUS;
};

export const getChannelName = (channel: ChannelResponse | undefined) => {
  if (!channel) {
    return null;
  }

  const { cageName, name } = channel as RsiChannel;
  return `${cageName}-${name}`;
};

export const replaceAllCaseInsensitive = (
  text: string,
  textToReplace: string,
  replaceWith: string,
) => {
  // See http://stackoverflow.com/a/3561711/556609
  const esc = textToReplace.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
  const reg = new RegExp(esc, 'ig');
  return text.replace(reg, replaceWith);
};

export const emptyFunction = () => null;

// 300 is the max limit on stream API (https://getstream.io/chat/docs/other-rest/query_channels/?q=channelFilters#query-options)
export const MAX_RESULTS = 300;
