import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import Module from './lib';
import env from './lib/utils/env';

const root = ReactDOM.createRoot(document.getElementById(env.REACT_APP_IDENTIFIER!)!);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Module />
    </BrowserRouter>
  </React.StrictMode>,
);
