import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../interfaces';

const selectSelf = (state: RootState) => state.search;

// eslint-disable-next-line import/prefer-default-export
export const searchFiltersSelector = createSelector(
  selectSelf,
  (searchState) => searchState.filters,
);
