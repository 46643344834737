import React from 'react';
import { emptyFunction } from '../../../utils/common';
import { CustomActions } from '../CustomMessageActions';

interface ActionMenuProps {
  isUserBanned: boolean;
  isModUser: boolean;
  isProcessing: boolean;
  setAction: Function;
}

function ActionMenu({
  setAction, isUserBanned, isModUser, isProcessing,
}: ActionMenuProps) {
  const getBanActionText = () => {
    if (isProcessing) {
      return 'Processing';
    }
    return isUserBanned ? 'Unban User' : 'Ban User';
  };
  return (
    <div className="actions-container">
      {!isModUser && (
        <div
          className="action-item"
          role="button"
          onClick={() => setAction(CustomActions.BAN)}
          onKeyDown={emptyFunction}
          tabIndex={0}
        >
          {getBanActionText()}
        </div>
      )}
      <div
        className="action-item"
        role="button"
        onClick={() => setAction(CustomActions.DELETE)}
        onKeyDown={emptyFunction}
        tabIndex={0}
      >
        Delete
      </div>
    </div>
  );
}

export default ActionMenu;
