import { useCallback, useEffect, useState } from 'react';
import { useChatContext } from 'stream-chat-react';
import { ChannelResponse, UserResponse } from 'stream-chat';
import { logger } from '../services';
import useChannelsContext from '../contexts/ChannelsContext';
import { MAX_RESULTS } from '../utils/common';

type BanItem = {
  user: UserResponse;
  banned_by?: UserResponse;
  channel?: ChannelResponse;
  expires?: string;
  ip_ban?: boolean;
  reason?: string;
  timeout?: number;
};

type FetchOptions = {
  channelCids: string[];
  playerName: string;
};

type Result = {
  bannedUsers: BanItem[],
  isLoading: boolean
  fetch: (options:FetchOptions) => Promise<void>,
  refetchBannedUsers: () => Promise<void>,
}

interface UserFilters {
  user_id?: {
    $in: string[];
  };
}

const useGetUsersByCageId = ():Result => {
  const { client } = useChatContext();
  const { channels } = useChannelsContext();

  const [bannedUsers, setBannedUsers] = useState<BanItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const queryBannedUsers = useCallback(async () => {
    try {
      setIsLoading(true);

      const channelCids: string[] = [];
      channels.forEach((chl) => {
        channelCids.push(chl.channel.cid);
      });
      const { bans } = await client.queryBannedUsers(
        { channel_cid: { $in: channelCids } },
        [{ created_at: -1 }],
        { exclude_expired_bans: true, limit: MAX_RESULTS },
      );
      setBannedUsers(bans || []);
    } catch (err) {
      logger.error('Error when trying to fetch banned users: ', err);
    } finally {
      setIsLoading(false);
    }
  }, [client, channels]);

  useEffect(() => {
    queryBannedUsers();
  }, [queryBannedUsers]);

  const getUserFilters = async (playerName:string) => {
    if (!playerName) return {};

    const { users } = await client.queryUsers(
      {
        name: { $autocomplete: playerName },
      },
    );

    const userIds = users.map((user) => user.id);
    return { user_id: { $in: userIds } };
  };

  const fetchBy = async (options: FetchOptions) => {
    const { channelCids, playerName } = options;
    const ids = channelCids.length === 0 ? channels.map((el) => el.channel.cid) : channelCids;

    setIsLoading(true);
    try {
      const userFilters: UserFilters = await getUserFilters(playerName);

      if (userFilters?.user_id?.$in.length === 0) {
        setBannedUsers([]);
        return;
      }
      const queryOptions = {
        channel_cid: { $in: ids },
        ...(playerName && userFilters),
      };

      const { bans } = await client.queryBannedUsers(
        queryOptions,
        [{ created_at: -1 }],
        {
          exclude_expired_bans: true,
          limit: MAX_RESULTS,
        },
      );
      setBannedUsers(bans || []);
    } catch (err) {
      logger.error('Error when trying to fetch banned users: ', err);
    } finally {
      setIsLoading(false);
    }
  };

  const state = {
    bannedUsers,
    isLoading,
    fetch: fetchBy,
    refetchBannedUsers: queryBannedUsers,
  };
  return state;
};

export default useGetUsersByCageId;
