import React, { useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useChatContext } from 'stream-chat-react';
import { SelectRef } from '../../interfaces';
import useChannelsContext from '../../contexts/ChannelsContext';
import { pinnedMessageSelector } from '../../store/selectors/pinnedMessage';
import { setNoneOverlay } from '../../store/actions/overlay';
import { setPinnedMessage } from '../../store/actions/pinnedMessage';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 500px;
  font-family: var(--second-font);

  .channelSelector {
    width: 500px
  }
`;

const TextArea = styled.textarea`
  margin: 20px 0;
  align-self: stretch;
  height: 200px;
  border-radius: 8px;
  padding: 10px;

  &:focus-visible {
    outline-color: #005fff;
  }
`;

const Input = styled.input`
  margin: 20px 0;
  align-self: stretch;
  border-radius: 8px;
  padding: 10px;

  &:focus-visible {
    outline-color: #005fff;
  }
`;

const SubmitBtn = styled.button`
  background-color: #005fff;
  color: #fff;
  border: none;
  border-radius: 8px;
  font-size: medium;
  height: 40px;
  align-self: stretch;
`;

function PinMessage() {
  const dispatch = useDispatch();
  const { channels } = useChannelsContext();
  const { client } = useChatContext();
  const pinnedMessage = useSelector(pinnedMessageSelector);
  const selectRef = useRef<SelectRef>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const [text, setText] = useState<string | undefined>(undefined);
  const [callToAction, setCallToAction] = useState<string | undefined>(undefined);
  const [title, setTitle] = useState<string | undefined>(undefined);
  const [channelSelect, setChannelSelect] = useState<Array<any> | undefined>(undefined);

  useEffect(() => {
    if (pinnedMessage) {
      setCallToAction(pinnedMessage.callToAction);
      setTitle(pinnedMessage.title);
      setText(pinnedMessage.text);
      setChannelSelect([channels.find((channel) => channel.value === pinnedMessage.cid)]);
    }

    return () => {
      dispatch(setPinnedMessage(undefined));
    };
  }, [pinnedMessage, channels, dispatch]);

  const onSubmitClick = () => {
    if (pinnedMessage) {
      client.partialUpdateMessage(pinnedMessage.id, {
        set: {
          text,
          title,
          callToAction,
        },
      });
    } else {
      selectRef.current?.getValue().forEach(({ channel }) => channel.sendMessage({
        text: textareaRef.current?.value,
        pinned: true,
        callToAction,
        title,
      }));
    }
    dispatch(setNoneOverlay());
  };

  return (
    <form onSubmit={() => onSubmitClick()}>
      <Container>
        <Select
          ref={selectRef}
          name="channelSelector"
          required
          options={channels}
          isMulti
          className="channelSelector"
          onChange={(e) => {
            setChannelSelect(e);
          }}
          value={channelSelect}
          isDisabled={pinnedMessage !== undefined}
        />

        <Input
          placeholder="Title"
          required
          onChange={(e) => {
            setTitle(e.target.value);
          }}
          value={title}
        />
        <TextArea
          placeholder="Pin Message"
          required
          ref={textareaRef}
          onChange={(e) => {
            setText(e.target.value);
          }}
          value={text}
        />
        <Input
          placeholder="Call to Action"
          required
          onChange={(e) => {
            setCallToAction(e.target.value);
          }}
          value={callToAction}
        />
        <SubmitBtn type="submit">Submit</SubmitBtn>
      </Container>
    </form>
  );
}

export default PinMessage;
