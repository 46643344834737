import React from 'react';
import { BanUserOptions, UserResponse } from 'stream-chat';
import { useChannelStateContext, useChatContext } from 'stream-chat-react';
import styled from 'styled-components';
import { logger } from '../../../services';

export enum UnitOfTime {
  DAY = 'DAY',
  HOUR = 'HOUR',
  MINUTE = 'MINUTE'
}

const DurationFormContainer = styled.div`
  display: flex;
`;

const TextSpan = styled.span`
  display: block;
  padding: 4px;
  word-wrap: break-word;
  white-space: normal;
  font-size: ${({ theme }) => theme.fontSize.text_sm};
`;

const durationText = 'Please select the duration for the ban';

interface BanActionProps {
  user: UserResponse;
  handleAction: Function;
  isProcessing: boolean;
  isUserBanned: boolean;
}

function BanAction({
  user, handleAction, isProcessing, isUserBanned,
}: BanActionProps) {
  const { channel, channelCapabilities } = useChannelStateContext();
  const { client } = useChatContext();
  const confirmationText = isUserBanned ? 'Are you sure you want to unban this player?' : 'Please provide a reason';

  const handleBanUnbanAction = async (event: React.FormEvent) => {
    event.preventDefault();
    // Do not perform ban/unban operation if channel doesn't have sufficient permissions
    // Do not perform ban/unban operation if user ban status is undefined. It could be possible
    if (!channelCapabilities?.['ban-channel-members'] || typeof isUserBanned === 'undefined') {
      logger.warn(`${isUserBanned ? 'Ban' : 'Unban'} action was not allowed/performed`);
      return;
    }
    // explicitly checking on purpose
    if (isUserBanned) {
      await channel.unbanUser(user?.id as string);
      logger.info(`Unbanning user: ${user?.id}, on channel: ${channel.cid}`);
    } else {
      const target = event.target as typeof event.target & {
        reason: { value: string };
        duration: { value: string };
        unitOfTime: { value: string };
        reset: Function;
      };
      const reason = target.reason.value;
      const duration = target.duration.value;
      const unitOfTime = target.unitOfTime.value;

      if (!reason) {
        logger.error('Reason is required to ban user');
        return;
      }

      const banUserOptions: BanUserOptions = {
        banned_by_id: client?.userID,
        reason,
      };

      if (duration) {
        const convertedDuration = Number(duration);
        if (!Number.isNaN(convertedDuration)) {
          switch (unitOfTime) {
            case UnitOfTime.DAY:
              banUserOptions.timeout = convertedDuration * 24 * 60;
              break;
            case UnitOfTime.HOUR:
              banUserOptions.timeout = convertedDuration * 60;
              break;
            case UnitOfTime.MINUTE:
            default:
              banUserOptions.timeout = convertedDuration;
              break;
          }
        } else {
          logger.error('Unable to parse duration to number');
          return;
        }
      }

      // Ban user channel level
      await channel.banUser(user?.id as string, banUserOptions);
      logger.info(`Banning user ${user?.id} on channel: ${channel.cid}`, banUserOptions);
      target.reset();
    }
  };

  return (
    <form onSubmit={(e) => handleAction(e, handleBanUnbanAction)}>
      {isUserBanned ? (
        <TextSpan>
          {confirmationText}
        </TextSpan>
      ) : (
        <>
          <input
            type="text"
            id="reason"
            placeholder="Ban reason"
            required
          />
          <TextSpan>
            {durationText}
          </TextSpan>
          <DurationFormContainer>
            <input
              type="number"
              id="duration"
              placeholder="Ban duration"
            />
            <select
              name="unitOfTime"
              id="unitOfTime"
            >
              <option value={UnitOfTime.MINUTE}>Minute(s)</option>
              <option value={UnitOfTime.HOUR}>Hour(s)</option>
              <option value={UnitOfTime.DAY}>Day(s)</option>
            </select>
          </DurationFormContainer>
        </>
      )}
      <button
        className="action-confirm-btn"
        type="submit"
        disabled={isProcessing}
      >
        {isProcessing ? 'Processing...' : 'Confirm'}
      </button>
    </form>

  );
}

export default BanAction;
