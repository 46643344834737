import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';
import { FeedEvent, WinningParticipant } from '../../../interfaces';

interface TriviaMessageProps {
  feedEvent: FeedEvent;
}

const MessageContainer = styled.div`
  font-size: ${({ theme }) => theme.fontSize.text_sm};
  display: flex;
`;

const Message = styled.div`
  ${({ theme }) => css`
    font-weight: ${theme.fontWeight.medium};
    margin-left: 8px;
  `}
`;

export default function TriviaMessage({ feedEvent }: Readonly<TriviaMessageProps>) {
  const buildMessage = useCallback((winner: WinningParticipant) => {
    const nickname = winner.nickname === '' ? '[anonymous]' : winner.nickname;
    return `@${nickname}`;
  }, []);

  const buildWinnerMessage = useCallback((winners: WinningParticipant[]) => {
    winners.sort((winnerA, winnerB) => winnerA.position - winnerB.position);
    return winners.map((winner) => {
      let suffix = '';
      switch (winner.position) {
        case 1:
          suffix = 'st';
          break;
        case 2:
          suffix = 'nd';
          break;
        case 3:
          suffix = 'rd';
          break;
        default:
          break;
      }
      return `${winner.position}${suffix} ${buildMessage(winner)}`;
    });
  }, [buildMessage]);

  const buildRandomWinnerMessage = useCallback(
    (winners: WinningParticipant[]) => winners.map((winner) => buildMessage(winner)),
    [buildMessage],
  );

  return (
    <MessageContainer>
      <Message>
        🏁 A Trivia Game has finished.
        <br />
        {
          feedEvent.winners
          && (
            <>
              🏅 Congratulations to our winners:
              <br />
              {buildWinnerMessage(feedEvent.winners)}
              <br />
            </>
          )
        }
        {
          feedEvent.randomWinners
          && (
            <>
              Random Winners
              <br />
              {buildRandomWinnerMessage(feedEvent.randomWinners)}
              <br />
            </>
          )
        }
        🤩 Thanks to all our participants; it was a
        very exciting game, and we want to see you again soon.
      </Message>
    </MessageContainer>
  );
}
