import { createReducer } from '@reduxjs/toolkit';
import { ChannelOperationsState } from '../../interfaces';
import { channelOperationsActions } from '../actions';

const initialState: ChannelOperationsState = {
  deletedChannel: undefined,
};

export default createReducer(initialState, (builder) => {
  builder
    .addCase(channelOperationsActions.deleteChannel, (state, { payload: channel }) => {
      state.deletedChannel = channel;
    });
});
