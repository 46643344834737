export default {
  REACT_APP_TAG: process.env.REACT_APP_TAG,
  REACT_APP_IDENTIFIER: process.env.REACT_APP_IDENTIFIER,
  REACT_APP_TEAM: process.env.REACT_APP_TEAM,
  PUBLIC_URL: process.env.PUBLIC_URL,
  REACT_APP_STREAM_API_KEY: process.env.REACT_APP_STREAM_API_KEY,
  REACT_APP_OKTA_CLIENT_ID: process.env.REACT_APP_OKTA_CLIENT_ID,
  REACT_APP_LAMBDA_URL: process.env.REACT_APP_LAMBDA_URL,
  REACT_APP_DEBUG_LOG_URL: process.env.REACT_APP_DEBUG_LOG_URL,
  REACT_APP_ENV: process.env.REACT_APP_ENV,
};
