import React from 'react';
import { StreamMessage, useChannelStateContext } from 'stream-chat-react';
import styled from 'styled-components';
import { useCustomChatContext } from '../../contexts/CustomChatContext';
import { TRIVIA, getDisplayName } from '../../utils/common';
import CustomMessageActions from './CustomMessageActions';
import { linkifyMessage, stringToColour } from '../../utils/messages';
import { isUserAdminOrModerator } from '../../utils/user';
import { RsiChatGenerics } from '../../interfaces';
import EventEmitter from '../../utils/eventEmitter';
import TriviaMessage from '../FeedMessage/TriviaMessage';

const CustomMessageTextWrapper = styled.div`
  width: 100%;

  .content {
    display: flex;
    > .avatar {
      text-align: center;
      margin-right: 4px;
    }

    > .message-text-wrapper{
      word-break: break-all;
      margin-left: 5px;
      margin-top: 2px;
        > .message-owner {
        display: inline;
        margin-right: 8px;
        font-weight: ${({ theme }) => theme.fontWeight.regular};
        font-size: ${({ theme }) => theme.fontSize.text_sm};
        min-width: fit-content;
        color: ${({ theme }) => theme.colors.primary_100};
        position: relative;
        cursor: pointer;
      }

      > .message-owner.self{
        font-weight: ${({ theme }) => theme.fontWeight.bold};
        color: ${({ theme }) => theme.colors.primary_100};
        background-color: ${({ theme }) => theme.colors.primary_300};
        padding: 2px 4px;
        border-radius: 25px;
      }

      > .message {
        display: inline;
        font-size: ${({ theme }) => theme.fontSize.text_md};
        font-weight: ${({ theme }) => theme.fontWeight.medium};
        color: ${({ theme }) => theme.colors.black};
        mix-blend-mode: normal;
        position: relative;
        word-break: break-word;
      }
    }
  }

  .message-time{
    display: none;
    float: right;
    margin-right: 8px;
    font-weight: ${({ theme }) => theme.fontWeight.regular};
    font-size: ${({ theme }) => theme.fontSize.text_xs};
    min-width: fit-content;
    color: ${({ theme }) => theme.colors.neutral_500};
    position: relative;
  }
`;

export type MessageTextProps = {
  message: StreamMessage<RsiChatGenerics>;
  isMyMessage: boolean;
}

function parseMessageTime(message: StreamMessage) {
  return message.created_at?.toLocaleString();
}

function UnMemoizedMessageTextComponent(props: MessageTextProps) {
  const { message, isMyMessage } = props;
  const [isMessageActionsActive, setIsMessageActionsActive] = React.useState(false);
  const isDeleted = message.type === 'deleted';
  const { isReadOnly } = useCustomChatContext();
  const { channel } = useChannelStateContext();

  const isAdminOrMod = isUserAdminOrModerator(message.user);
  const displayName = getDisplayName(message, channel.cid);
  const nameColour = stringToColour(displayName);

  const onNameClick = () => {
    EventEmitter.emit('nicknameClicked', { content: displayName, channelId: channel.id });
  };

  const renderMessage = () => {
    if (isDeleted) {
      return <div className="message">This message was deleted.</div>;
    }
    if (isAdminOrMod) {
      if (message.feedEvent) {
        if (message.feedEvent.gameCode === TRIVIA) {
          return <TriviaMessage feedEvent={message.feedEvent} />;
        }
        return <div className="message">{message.feedEvent.message}</div>;
      }
      // eslint-disable-next-line react/no-danger
      return <div className="message" dangerouslySetInnerHTML={{ __html: linkifyMessage(message.text) }} />;
    }
    return <div className="message">{message.text}</div>;
  };

  return (
    <CustomMessageTextWrapper
      onMouseEnter={() => setIsMessageActionsActive(true)}
      onMouseLeave={() => setIsMessageActionsActive(false)}
    >
      <div className="content">
        <div className="message-text-wrapper">
          <div
            onClick={onNameClick}
            onKeyDown={() => null}
            style={!isMyMessage ? { color: nameColour } : {}}
            className={`message-owner ${isMyMessage ? 'self' : ''}`}
            role="button"
            tabIndex={0}
          >
            {displayName}
          </div>
          {renderMessage()}
        </div>
      </div>
      <div className="message-time">
        {parseMessageTime(message)}
      </div>
      {!isDeleted && !isReadOnly && (
        <CustomMessageActions isActive={isMessageActionsActive} message={message} />
      )}
    </CustomMessageTextWrapper>
  );
}

export const CustomMessageText = React.memo(
  UnMemoizedMessageTextComponent,
) as typeof UnMemoizedMessageTextComponent;
