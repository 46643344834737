import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../interfaces';

const selectSelf = (state: RootState) => state.pinnedMessage;

// eslint-disable-next-line import/prefer-default-export
export const pinnedMessageSelector = createSelector(
  selectSelf,
  ({ pinnedMessage }) => pinnedMessage,
);
