import { createReducer } from '@reduxjs/toolkit';
import { PinnedMessageState } from '../../interfaces/PinMessage';
import { pinnedMessageActions } from '../actions';

const initialState: PinnedMessageState = {
  pinnedMessage: undefined,
};

export default createReducer(initialState, (builder) => {
  builder
    .addCase(pinnedMessageActions.setPinnedMessage, (state, { payload: message }) => {
      state.pinnedMessage = message;
    })
    .addCase(pinnedMessageActions.resetPinnedMessage, (state) => {
      state.pinnedMessage = undefined;
    });
});
