// Max's personal okta account
// const CLIENT_ID = process.env.CLIENT_ID || '0oa5vyp2rgzeT6wjg5d7';
// const ISSUER = process.env.ISSUER || 'https://dev-23481879.okta.com/oauth2/default';

// Daniel's personal okta account
// const CLIENT_ID = process.env.CLIENT_ID || '0oa5z60xbgkoaV61D5d7';
// const ISSUER = process.env.ISSUER || 'https://dev-5634618.okta.com/oauth2/default';

// RSI's OKTA
const CLIENT_ID = process.env.REACT_APP_OKTA_CLIENT_ID;
const ISSUER = process.env.ISSUER || 'https://rushstreetinteractive.okta.com';

const OKTA_TESTING_DISABLEHTTPSCHECK = process.env.OKTA_TESTING_DISABLEHTTPSCHECK || false;
const BASENAME = process.env.PUBLIC_URL || '';
const REDIRECT_URI = `${window.location.origin}${BASENAME}/login/callback`;

export default {
  oidc: {
    clientId: CLIENT_ID,
    issuer: ISSUER,
    redirectUri: REDIRECT_URI,
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
    disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK,
  },
};
