import React from 'react';
import { Provider } from 'react-redux';
import {
  Navigate, Route, Routes, useNavigate,
} from 'react-router-dom';
import {
  LoginCallback, Security,
} from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { InitArgs } from './interfaces';
import store from './store';
import App from './App';
import RequireAuth from './components/RequireAuth/RequireAuth';

import config from './config';

const oktaAuth = new OktaAuth(config.oidc);

function Module({ initialState }: InitArgs) {
  const navigate = useNavigate();

  const restoreOriginalUri = async (_oktaAuth: OktaAuth, originalUri: string) => {
    navigate(toRelativeUrl(originalUri || '/', window.location.origin), { replace: true });
  };

  return (
    <Security
      oktaAuth={oktaAuth}
      restoreOriginalUri={restoreOriginalUri}
    >
      <Routes>
        <Route
          path="/"
          element={(
            <RequireAuth>
              <Provider store={store(initialState)}>
                <App />
              </Provider>
            </RequireAuth>
          )}
        />
        {/* This is useful for local dev as OKTA cannot be used locally */}
        <Route
          path="/skipAuth"
          element={(
            <Provider store={store(initialState)}>
              <App />
            </Provider>
          )}
        />
        <Route path="/login/callback" element={(<LoginCallback />)} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Security>
  );
}

export default Module;
