import { useOktaAuth } from '@okta/okta-react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { EventTypes, LogLevel, StreamChat } from 'stream-chat';
import { AuthenticationResponse } from '../interfaces';
import { httpClient, logger, sanitizeAxiosInstance } from '../services';
import { streamActions } from '../store/actions';
import { streamIdSelector, streamTokenSelector } from '../store/selectors/stream';
import env from '../utils/env';

const apiKey = env.REACT_APP_STREAM_API_KEY;
const url = env.REACT_APP_LAMBDA_URL;

const streamLogConverter = (
  logLevel: LogLevel,
  message: string,
  extraData?: Record<string, unknown>,
) => {
  // let's log only the error and warns from stream lib, anything below that would be too noisy
  if (logLevel === 'error') {
    logger.error(message, extraData);
  } else if (logLevel === 'warn') {
    logger.warn(message, extraData);
  }
};

function useStreamClient() {
  const dispatch = useDispatch();
  const [client, setClient] = useState<StreamChat>();
  const streamToken = useSelector(streamTokenSelector);
  const id = useSelector(streamIdSelector);

  const { authState } = useOktaAuth();
  const { pathname } = useLocation();
  useEffect(() => {
    const streamClient = StreamChat.getInstance(apiKey!, {
      timeout: 9000,
      logger: streamLogConverter,
    });
    sanitizeAxiosInstance(streamClient.axiosInstance);

    const eventTypesToLog: EventTypes[] = ['connection.changed', 'connection.recovered'];
    const eventSub = streamClient.on((event) => {
      if (eventTypesToLog.indexOf(event.type) !== -1) {
        logger.info('Stream event', event);
      }
    });

    async function initSteamChatClient() {
      await streamClient.connectUser(
        {
          id: id!,
        },
        async () => {
          if (pathname === '/skipAuth') {
            return streamToken!;
          }
          try {
            logger.info('Authenticating with stream');
            const response = await httpClient.get<AuthenticationResponse>(
              url!,
              { headers: { Authorization: `Bearer ${authState?.accessToken?.accessToken!}` } },
            );
            dispatch(streamActions.setStreamAuthInfo(
              response.data.token,
              response.data.id,
            ));
            return response.data.token;
          } catch (e) {
            logger.error('Error refreshing mod session', e);
            // eslint-disable-next-line no-alert
            alert('Could not refresh your session. Please refresh the page');
            throw new Error();
          }
        },
      );
      setClient(streamClient);
    }

    // eslint-disable-next-line no-underscore-dangle
    if (!streamClient._user) {
      logger.info('Connecting with player', id);
      initSteamChatClient();
    }

    return () => {
      logger.info('Stream client destroyed', id);
      eventSub.unsubscribe();
    };
  }, [client, id, streamToken, authState?.accessToken?.accessToken, pathname, dispatch]);

  // Moved clean up to separate useEffect, because it was called whenever avatar object changed
  // which happens several times and causes errors.
  useEffect(() => () => {
    if (client) {
      logger.info('Disconnecting player', id);
      client.disconnectUser();
    }
  }, [client, id]);

  return client;
}

export default useStreamClient;
