import { useState, useEffect } from 'react';
import { useChatContext, useChannelStateContext } from 'stream-chat-react';
import { logger } from '../services';

export default function useUserBanStatus(userId: string, isActive: boolean) {
  const [isBanned, setIsBanned] = useState(false);
  const [isModUser, setIsModUser] = useState(false);
  const [isProcessing, setIsProcessing] = useState(true);
  const { client } = useChatContext();
  const { channel } = useChannelStateContext();

  useEffect(() => {
    const queryBannedUsers = async () => {
      try {
        setIsProcessing(true);
        const { bans } = await client.queryBannedUsers(
          { channel_cid: { $in: [channel.cid] }, user_id: userId },
          undefined,
          { exclude_expired_bans: true },
        );
        setIsBanned(!!bans?.length);
        setIsProcessing(false);
      } catch (err) {
        logger.error(`Error when fetching banned users in channel ${channel.cid}: `, err);
      }
    };

    if (isActive) {
      if (client.user?.id === userId) {
        setIsModUser(true);
        setIsProcessing(false);
      } else {
        queryBannedUsers();
      }
    }
  }, [channel.cid, client, isBanned, userId, isActive]);

  return { isBanned, isModUser, isProcessing };
}
