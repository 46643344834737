/* eslint-disable react/no-danger */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MessageResponse } from 'stream-chat';
import { StreamMessage } from 'stream-chat-react';
import styled from 'styled-components';
import useChannelsContext from '../../../contexts/ChannelsContext';
import { setNoneOverlay } from '../../../store/actions/overlay';
import { searchFiltersSelector } from '../../../store/selectors/search';
import {
  getChannelName, getDisplayName, JUMP_TO_MESSAGE_EVENT, replaceAllCaseInsensitive,
} from '../../../utils/common';
import { RsiChatGenerics } from '../../../interfaces';

interface ChannelGroupMessagesProps {
  messages: MessageResponse[];
}

const ChannelGroupWrapper = styled.div`
  margin-top: 10px;
  .channel-name{
    font-weight: bold;
  }

  .msg-count{
    margin-left: 8px;
    font-size: 12px;
  }
`;

interface MessageDetailsProps {
  message: MessageResponse;
}

const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  row-gap: 5px;
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.colors.neutral_100};
  }

  .date{
    font-weight: ${({ theme }) => theme.fontWeight.regular};
    font-size: ${({ theme }) => theme.fontSize.text_sm};
    color: ${({ theme }) => theme.colors.neutral_500};
    float: right;
  }

  .name{
    font-weight: ${({ theme }) => theme.fontWeight.regular};
    font-size: ${({ theme }) => theme.fontSize.text_sm};
    color: ${({ theme }) => theme.colors.neutral_500};
  }

  .text{
    font-weight: ${({ theme }) => theme.fontWeight.regular};
    font-size: ${({ theme }) => theme.fontSize.text_sm};
    color: ${({ theme }) => theme.colors.black};
  }
`;

function highlightText(html: string, searchText?: string) {
  if (!searchText) return html;
  return replaceAllCaseInsensitive(html, searchText, `<mark>${searchText}</mark>`);
}

function MessageDetails(props: MessageDetailsProps) {
  const { message } = props;
  const { html, created_at: createdAt } = message;
  const { keyword } = useSelector(searchFiltersSelector)!;
  const dt = new Date(createdAt!);
  const { setActiveChannelById } = useChannelsContext();
  const dispatch = useDispatch();

  const onMessageClick = () => {
    if (message.channel) {
      setActiveChannelById(message.channel.cid);
      // the timeout is necessary to let the channel selection be done first
      // if you try to jumpToMessage in an inactive channel it will throw an error
      setTimeout(() => {
        const event = new CustomEvent(JUMP_TO_MESSAGE_EVENT, { detail: message.id });
        window.dispatchEvent(event);
      }, 200);
      dispatch(setNoneOverlay());
    }
  };

  return (
    <MessageWrapper onClick={onMessageClick}>
      <div>
        <span className="name">
          {getDisplayName(message as StreamMessage<RsiChatGenerics>)}
        </span>
        <span className="date">
          {`${dt.toLocaleDateString()} ${dt.toLocaleTimeString()}`}
        </span>
      </div>

      <div className="text" dangerouslySetInnerHTML={{ __html: highlightText(html!, keyword) }} />

    </MessageWrapper>
  );
}

function ChannelGroupMessages(props: ChannelGroupMessagesProps) {
  const { messages } = props;

  return (
    <ChannelGroupWrapper>
      <span className="channel-name">{getChannelName(messages[0].channel)}</span>
      <span className="msg-count">{`${messages.length} message(s) found`}</span>

      {messages.map((message) => (
        <MessageDetails
          key={message.id}
          message={message}
        />
      ))}

    </ChannelGroupWrapper>
  );
}

export default ChannelGroupMessages;
