import styled from 'styled-components';

interface ColProps {
  col?: number
  gap?: string
}

const Col = styled.div<ColProps>`
  flex: 1;
  padding: 0 ${({ gap }) => gap || '15px'};
  max-width: ${({ col }) => (col ? `${(col / 12) * 100}%` : '100%')};
`;

export default Col;
