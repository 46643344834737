import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Chat, enTranslations, Streami18n } from 'stream-chat-react';

// @ts-ignore
import 'stream-chat-react/dist/css/index.css';
import styled from 'styled-components';
import { ChannelsProvider } from '../../contexts/ChannelsContext';
import { CustomChatProvider } from '../../contexts/CustomChatContext';
import useStreamClient from '../../hooks/useStreamClient';
import { OverlayType } from '../../interfaces';
import { overlaySelector } from '../../store/selectors/overlay';
import ChannelContainer from '../ChannelContainer/ChannelContainer';
import Sidebar from '../Sidebar/Sidebar';
import Overlay from '../Overlay/Overlay';

const AppWrapper = styled.div<{ overlayType: OverlayType }>`
  --primary-color: #005fff;
  --primary-color-alpha: #005fff1a;
  /* Removed relative position so Overlay takes the whole window space */
  display: flex;
  flex: 1;
  height: 100vh;
  margin: 0;
  padding: 0;
  overflow-x: ${({ overlayType }) => (overlayType === OverlayType.PinMessage ? 'hidden' : 'auto')};

  .str-chat-channel-list {
    height: fit-content;
    margin-bottom: 8px;
    background: none;
  }
  
  .str-chat-channel {
    height: 50vh;
    max-height: 100vh;
    width: 500px;
    border: 1px solid var(--primary-color);
    resize: both;
    overflow: auto;
    min-height: 50%;
    min-width: 450px;
  }
  
  .str-chat__load-more-button {
    display: none;
  }
  
  .str-chat__input-footer {
    display: none;
  }
  
  .str-chat__date-separator {
    margin: 16px 24px;
  }
  
  .str-chat__message-notification {
    background: var(--primary-color);
    cursor: pointer;
  }
  
  @media screen and (max-width: 960px) {
    .str-chat-channel-list.team {
      position: unset;
      left: unset;
      top: unset;
      z-index: unset;
      min-height: unset;
      overflow-y: unset;
      box-shadow: unset;
      transition: unset;
    }
  }
`;

const i18nInstance = new Streami18n({
  language: 'en',
  translationsForLanguage: {
    ...enTranslations,
  },
});

function ChatContainer() {
  const client = useStreamClient();
  const overlayType = useSelector(overlaySelector);

  useEffect(() => {
    const handleColorChange = (color: string) => {
      const root = document.documentElement;
      if (color.length && color.length === 7) {
        root.style.setProperty('--primary-color', `${color}E6`);
        root.style.setProperty('--primary-color-alpha', `${color}1A`);
      }
    };

    window.addEventListener('message', (event) => handleColorChange(event.data));
    return () => {
      window.removeEventListener('message', (event) => handleColorChange(event.data));
    };
  }, []);

  if (!client) {
    return null;
  }

  return (
    <AppWrapper overlayType={overlayType}>
      <CustomChatProvider client={client}>
        <Chat {...{ client, i18nInstance }} theme="team light">
          <ChannelsProvider>
            <Sidebar />
            <ChannelContainer />
            <Overlay />
          </ChannelsProvider>
        </Chat>
      </CustomChatProvider>
    </AppWrapper>
  );
}

export default ChatContainer;
