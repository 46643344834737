import { ChannelFilters, ChannelOptions, ChannelSort } from 'stream-chat';
import { CHANNEL_TYPE } from './common';
import { RsiChatGenerics } from '../interfaces';

export const sortDefault: ChannelSort = { last_message_at: -1, updated_at: -1 };

export const filtersDefault: ChannelFilters<RsiChatGenerics> = { type: CHANNEL_TYPE };

export const optionsStateful: ChannelOptions = {
  state: true, watch: true, presence: true, message_limit: 100,
};

export const optionsStateless = {
  state: false,
  watch: false,
  member_limit: 0,
  message_limit: 20,
};
