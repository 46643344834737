import axios from 'axios';

const httpClient = axios.create({
  baseURL: axios.defaults.baseURL,
  params: {},
});

// interceptor to sanitize the error returned
// important to avoid having sensitive information in the logs
export const sanitizeAxiosInstance = (axiosInstance: any) => {
  axiosInstance.interceptors.response.use(
    (response: any) => response,
    (error: any) => {
      const sanitizedError = {
        message: error?.message,
        name: error?.name,
        stack: error?.stack,
        method: error?.config?.method,
        url: error?.config?.url,
        status: error?.status,
      };
      return Promise.reject(sanitizedError);
    },
  );
};

sanitizeAxiosInstance(httpClient);
export default httpClient;
