import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../interfaces';

const selectSelf = (state: RootState) => state.channelOperations;

const deletedChannelSelector = createSelector(
  selectSelf,
  (channelOperations) => channelOperations.deletedChannel,
);

export default deletedChannelSelector;
