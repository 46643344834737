import { ChannelPreviewUIComponentProps } from 'stream-chat-react';

import React from 'react';
import { Channel } from 'stream-chat';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { closeChannel, openChannel } from '../../store/actions/channelTabs';
import { activeChannelIds } from '../../store/selectors/channelTabs';
import { RsiChatGenerics } from '../../interfaces';

const ChannelPreviewWrapper = styled.div`

  height: 37px;
  display: flex;
  align-items: center;

  &.selected {
    height: 37px;
    display: flex;
    align-items: center;
    background: rgba(0, 0, 0, 0.2);
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    font-weight: bold;
    cursor: pointer;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.2);
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    font-weight: bold;
    cursor: pointer;
  }

  .channel-preview__item {
    display: flex;
    align-items: center;
    font-family: Helvetica Neue, sans-serif;
    font-size: 14px;
    color: #ffffff;
    padding-left: 16px;
    height: 100%;
    width: 100%;
    text-overflow: ellipsis;
  }

  .channel-preview__item.multi {
    max-width: 220px;
  }

  .channel-preview__item.single .str-chat__avatar {
    margin-right: 12px;
  }

  .channel-preview__item.multi .str-chat__avatar {
    margin-right: 0;
  }

  .channel-preview__item.multi span:first-child {
    position: relative;
    z-index: 2;
    bottom: 6px;
  }

  .channel-preview__item.multi div:nth-child(2) {
    position: relative;
    right: 10px;
    z-index: 1;
    margin-bottom: 0px;
  }

  .channel-preview__item.multi p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

const PreviewDiv = styled.div`
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
`;

type PreviewProps = {
  channel: Channel<RsiChatGenerics>;
}

function Preview({ channel }: PreviewProps) {
  const channelName = `${channel?.data?.cageName || ''} ${channel?.data?.cageName ? '-' : ''} ${channel?.data?.name}`;
  return (
    <PreviewDiv role="button">
      <span className="channel-preview__item">
        {channelName}
      </span>
    </PreviewDiv>
  );
}

function CustomChannelListItem(props: ChannelPreviewUIComponentProps<RsiChatGenerics>) {
  const dispatch = useDispatch();
  const { channel } = props;
  const activeChannels = useSelector(activeChannelIds);
  const isActive = activeChannels.includes(channel.id);

  const onClick = () => {
    if (isActive) {
      dispatch(closeChannel(channel));
    } else {
      dispatch(openChannel(channel));
    }
  };

  return (
    <ChannelPreviewWrapper
      onKeyDown={() => null}
      role="button"
      tabIndex={0}
      className={isActive
        ? 'selected'
        : ''}
      onClick={onClick}
    >
      <Preview channel={channel} />
    </ChannelPreviewWrapper>
  );
}

export default CustomChannelListItem;
