import React from 'react';
import { useMessageContext } from 'stream-chat-react';
import styled, { css } from 'styled-components';
import { useDispatch } from 'react-redux';
import { RsiChatGenerics } from '../../interfaces';
import LazyCustomEmoji from './LazyCustomEmoji';
import { MAX_EMOJIS_DISPLAYED, getReactionsToRender } from '../../utils/reactionHelpers';
import { setReactionDetailsOverlay } from '../../store/actions/overlay';

const ReactionListContainer = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: row;
  margin-left: 30px;
  gap: 8px;

  ${({ theme }) => css`
    font-size: ${theme.fontSize.text_xs};
    line-height: ${theme.lineHeight.text_xs};
    font-weight: ${theme.fontWeight.medium};
  `}
`;

const ReactionItem = styled.div<{active: boolean}>`
  height: 22px;
  width: fit-content;
  min-width: 38px;
  max-width: 48px;
  display: flex;
  flex-direction: row;
  padding: 4px 6px;
  border-radius: 10px;
  cursor: pointer;
  gap: 2px;
  align-items: center;

  ${({ theme, active }) => css`
    background-color: ${active ? theme.colors.neutral_200 : theme.colors.neutral_100};
    color: ${theme.colors.neutral_400};

    &:hover {
      background-color: ${theme.colors.neutral_200};
      color: ${theme.colors.neutral_500};
    }
  `}
`;

const MoreItemsSection = styled.div`
  height: 22px;
  width: fit-content;
  padding: 4px 6px;
  border-radius: 10px;
  cursor: pointer;
  ${({ theme }) => css`
    background-color: ${theme.colors.primary_100};
    color: ${theme.colors.primary_300};
    &:hover {
      background-color: ${theme.colors.primary_200};
    }
  `}
`;

function UnMemoizedReactionsList() {
  const dispatch = useDispatch();
  const { message } = useMessageContext<RsiChatGenerics>('ReactionsList');
  const { own_reactions: ownReactions, latest_reactions: reactions } = message;

  if (!reactions?.length) return null;

  const ownReactionType = (ownReactions?.length
    ? ownReactions[0].type
    : undefined);

  const reactionCounts = message.reaction_counts! as { [key: string]: number };
  const uniqueReactionTypes = Object.keys(reactionCounts).length;
  const reactionsToRender = getReactionsToRender(reactionCounts, ownReactionType);
  const additionalEmojisCount = uniqueReactionTypes - MAX_EMOJIS_DISPLAYED;

  const onReactionListClick = () => {
    dispatch(setReactionDetailsOverlay(message.id));
  };

  return (
    <ReactionListContainer
      aria-label="Reaction list"
      data-testid="reaction-list"
    >
      {reactionsToRender.map(
        ([type, count]) => (
          <ReactionItem
            active={ownReactionType === type}
            key={type}
            onClick={onReactionListClick}
            aria-label="Reaction list item"
            data-testid={`reaction-list-item-${type}`}
          >
            <LazyCustomEmoji
              type={type}
              size={14}
            />
            <span>{count}</span>
          </ReactionItem>
        ),
      )}
      {additionalEmojisCount > 0 ? (
        <MoreItemsSection
          onClick={onReactionListClick}
        >
          {`+${additionalEmojisCount} more`}
        </MoreItemsSection>
      ) : null}
    </ReactionListContainer>
  );
}

/**
 * Component that displays a list of reactions on a message.
 */
const ReactionList = React.memo(UnMemoizedReactionsList);
export default ReactionList;
