import React from 'react';
import { Channel as ChannelComponent } from 'stream-chat-react';

import styled from 'styled-components';
import { useSelector } from 'react-redux';
import Draggable from 'react-draggable';
import CreateChannel from '../CreateChannel/CreateChannel';
import EditChannel from '../EditChannel/EditChannel';
import CustomMessage from '../CustomMessage/CustomMessage';
import CustomMessageInput from '../CustomMessageInput/CustomMessageInput';
import ChannelInner from './ChannelInner';
import ChannelEmptyState from '../ChannelEmptyState/ChannelEmptyState';
import { useCustomChatContext } from '../../contexts/CustomChatContext';
import { channelTabsSelector } from '../../store/selectors/channelTabs';
import DeleteChannel from '../DeleteChannel/DeleteChannel';

const ChannelContainerWrapper = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;

  .str-chat__list {
    padding: 0 8px;
  }

  .str-chat__container{
    background-color: white;
  }

  .str-chat__list .str-chat__reverse-infinite-scroll {
    padding-top: 0px;
  }

  .str-chat__li:empty {
    display: none;
  }

  .str-chat__ul > li:last-child{
    padding-bottom: 20px;
  }

  .str-chat__date-separator{
    padding: 0 40px;
    margin: 0 24px;
  }

  .str-chat__li--bottom, .str-chat__li--top, .str-chat__li--single {
    padding-bottom: 0;
    padding-top: 0;
  }

  .custom-thread-header {
    height: 62px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);
  }

  .custom-thread-header__left {
    display: flex;
    align-items: center;
    margin-left: 20px;
  }

  .custom-thread-header__left-title {
    font-family: Helvetica Neue, sans-serif;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #2c2c30;
    margin-right: 10px;
  }

  .custom-thread-header__left-count {
    font-family: Helvetica Neue, sans-serif;
    font-size: 14px;
    color: #858688;
  }

  .close-thread-icon {
    cursor: pointer;
    margin-right: 10px;
  }

  .str-chat__list--thread
    .str-chat__message-simple__actions__action--options
    .str-chat__message-actions-box {
    left: initial;
    right: 100%;
    border-radius: var(--border-radius-md);
  }

`;

function ChannelContainer() {
  const {
    isCreatingChannel,
    isEditingChannel,
    isDeletingChannel,
    setFocusedChannel,
  } = useCustomChatContext();

  const channelTabs = useSelector(channelTabsSelector);

  if (isCreatingChannel) {
    return (
      <ChannelContainerWrapper>
        <CreateChannel />
      </ChannelContainerWrapper>
    );
  }

  if (isEditingChannel) {
    return (
      <ChannelContainerWrapper>
        <EditChannel />
      </ChannelContainerWrapper>
    );
  }

  if (isDeletingChannel) {
    return (
      <ChannelContainerWrapper>
        <DeleteChannel />
      </ChannelContainerWrapper>
    );
  }

  return (
    <ChannelContainerWrapper className="box">
      {channelTabs.map((channel) => (
        <Draggable
          key={channel.id}
          handle=".drag-handle"
        >
          <div role="presentation" onClick={() => setFocusedChannel(channel)}>
            <ChannelComponent
              channel={channel}
              EmptyStateIndicator={ChannelEmptyState}
              Input={CustomMessageInput}
              Message={CustomMessage}
            >
              <ChannelInner />
            </ChannelComponent>
          </div>
        </Draggable>
      ))}
    </ChannelContainerWrapper>
  );
}

export default ChannelContainer;
