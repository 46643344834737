import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useCustomChatContext } from '../../contexts/CustomChatContext';
import CloseDeleteChannel from '../../assets/CloseDeleteChannel';
import { logger } from '../../services';
import { deleteChannel } from '../../store/actions/channelOperations';
import deletedChannelSelector from '../../store/selectors/channelOperations';
import { createCageName } from '../../utils/common';

const DeleteChannelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .delete-channel__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 62px;
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);
    padding-right: 20px;
    padding-bottom: 4px;
  }

  .delete-channel__header p {
    font-family: Helvetica Neue, sans-serif;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #2c2c30;
    margin-left: 20px;
  }

  .delete-channel__header svg {
    cursor: pointer;
  }
`;

const DeleteChannelContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 32px;
  width: 100%;

  .cageSelector {
    width: 500px;
  }
`;

const Input = styled.input`
  border-radius: 8px;
  padding: 10px;
  width: 475px;
  
  &:focus-visible {
    outline-color: #005fff;
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;

  span {
    color: red;
  }
`;

const SubmitBtn = styled.button`
  margin: 16px 0;
  background-color: #005fff;
  color: #fff;
  border: none;
  border-radius: 8px;
  font-size: medium;
  height: 40px;
  width: 500px;
  cursor: pointer;

  &:disabled {
    cursor: auto;
    background-color: lightgrey;
  }
`;

function DeleteChannel() {
  const dispatch = useDispatch();
  const channel = useSelector(deletedChannelSelector);
  const [confirmed, setConfirmation] = useState(false);
  const { setDeletingChannel } = useCustomChatContext();
  const inputRef = useRef<HTMLInputElement>(null);
  const cageName = createCageName(channel?.data?.cageCode, channel?.data?.name as string);

  const confirmDeletion = async (event: { preventDefault: () => void; }) => {
    event.preventDefault();

    try {
      await channel?.delete();
      setDeletingChannel(false);

      dispatch(deleteChannel(undefined));
    } catch (err) {
      logger.error(err);
    }
  };

  return (
    <DeleteChannelWrapper>
      <div className="delete-channel__header">
        <p>
          {`Delete Channel - ${cageName}`}
        </p>
        <CloseDeleteChannel />
      </div>

      <DeleteChannelContent>
        <p>Please confirm you want to delete this channel by typing the name of the channel.</p>
        <InputContainer>
          <Input
            onChange={() => setConfirmation(inputRef?.current?.value === cageName)}
            ref={inputRef}
            placeholder={`${cageName}`}
          />
        </InputContainer>
        <SubmitBtn
          disabled={!confirmed}
          onClick={confirmDeletion}
        >
          Delete Channel
        </SubmitBtn>
      </DeleteChannelContent>
    </DeleteChannelWrapper>
  );
}

export default DeleteChannel;
